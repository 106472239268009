import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store";
import { UserPreferencesExtended } from "../../apis/dto/UserPreferences";
import { Link } from "react-router-dom";

type Props = PropsFromRedux & {
  selectUserPreferences?: UserPreferencesExtended;
  ucmnetid?: string;
};

const UserAdminsOfDepartments = (props: Props) => {
  const { selectUserPreferences, ucmnetid } = props;
  if (
    selectUserPreferences &&
    selectUserPreferences.adminDepartments &&
    selectUserPreferences.adminDepartments.length > 0
  ) {
    return (
      <div className="AdminPreferencesLayout-adminInfo">
        <div className="MyPreferences-departmentInfo">
          <h2>Department Information</h2>
          {!selectUserPreferences.currentUserIsOwner && (
            <p>
              This person can edit the directory information for the following
              departments:
            </p>
          )}
          {selectUserPreferences.currentUserIsOwner && (
            <p>
              You can edit the directory information for the following
              departments:
            </p>
          )}
          {selectUserPreferences.adminDepartments.map((dept) => {
            return (
              <Link
                key={dept.code}
                to={`/departmentPreferences/${dept.code}?back=/adminPreferences/${ucmnetid}`}
                className="MyPreferences-departmentInfo-link"
              >
                {dept.name}
              </Link>
            );
          })}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  return {};
};

const mapDispatch = {};

const connector = connect(mapStateToProps, mapDispatch);

export default connector(UserAdminsOfDepartments);
