import departmentReducer from "./departments";
import peopleReducer from "./peopleSearch";
import authReducer from "./auth";
import userPreferencesReducer from "./userPreferences";
import studentPreferencesReducer from "./studentPersonalInfo";
import { reducer as toastrReducer } from "react-redux-toastr";
import globalStateReducer from "./globalStateReducer";

const rootReducer = {
  globalState: globalStateReducer,
  departments: departmentReducer,
  people: peopleReducer,
  auth: authReducer,
  userPreferences: userPreferencesReducer,
  studentPersonalInfo: studentPreferencesReducer,
  toastr: toastrReducer,
};

export default rootReducer;
