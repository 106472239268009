import React, { useEffect, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import SearchBar from "../../searchBar/searchBar";
import PeopleSearchResults from "../../peopleSearchResults/PeopleSearchResults";
import LoadingWheel from "../../loadingWheel/LoadingWheel";
import { RootState } from "../../../store";
import DepartmentList from "../../departmentList/DepartmentList";
import { setShowDepartmentList } from "../../../features/peopleSearch";
//import {toastr} from 'react-redux-toastr'

type Props = PropsFromRedux & {};

const Dashboard = (props: Props) => {
  const searchBarRef: React.MutableRefObject<any | null> = useRef(null);

  const { loading, showDepartemntList, setShowDepartmentList } = props;

  // const toastrOptions = {
  // 	// timeOut: 3000, // by setting to 0 it will prevent the auto close
  // 	//icon: (<myCustomIconOrAvatar />), // You can add any component you want but note that the width and height are 70px ;)
  // 	// onShowComplete: () => console.log('SHOW: animation is done'),
  // 	// onHideComplete: () => console.log('HIDE: animation is done'),
  // 	// onCloseButtonClick: () => console.log('Close button was clicked'),
  // 	// onToastrClick: () => console.log('Toastr was clicked'),
  // 	// showCloseButton: true, // false by default
  // 	// closeOnToastrClick: true, // false by default, this will close the toastr when user clicks on it
  // 	// component: ( // this option will give you a func 'remove' as props
  // 	//   <MyCustomComponent myProp="myValue">
  // 	// 	<span>Hello, World!</span>
  // 	//   </MyCustomComponent>
  // 	// )
  //   }
  // Second Argument Not provided: Runs after EVERY rendering
  // An empty array []: the side-effect runs once after the initial rendering
  // Has props or state values [prop1, prop2, ..., state1, state2]: the side-effect runs only when any depenendecy value changes.
  useEffect(() => {
    // Side-effect...
    // toastr.success('The title', 'The message');
    // toastr.info('The title', 'The message');
    // toastr.warning('The title', 'The message');
    // toastr.error('The title', 'The message');

    return function cleanup() {
      // Side-effect cleanup...
    };
  });
  const handleSearchBarSubmit = () => {
    console.log("searching");
    setShowDepartmentList(false);
  };
  //toastr.success('The title', 'The message');

  const onClearSearhBarHandle = () => {
    //ref.current.somemethodtocleartext();
    searchBarRef.current();
    console.log("text Cleared");
  };
  return (
    <div className="PageWrapper">
      <SearchBar ref={searchBarRef} onSearchSubmit={handleSearchBarSubmit} />
      <div className="Results">
        <LoadingWheel
          showLoading={loading === "pending"}
          spinnerOnly={false}
          numberOfDivs={4}
        />
      </div>

      {!showDepartemntList && (
        <PeopleSearchResults clearSearchBarText={onClearSearhBarHandle} />
      )}
      {showDepartemntList && <DepartmentList />}
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  return {
    loading: state.people.loading,
    showDepartemntList: state.people.showDepartemntList,
  };
};

const mapDispatch = {
  setShowDepartmentList,
};

const connector = connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
});

export default connector(Dashboard);
