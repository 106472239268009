import { useState, useEffect } from "react";
import parse from "html-react-parser";
import { Collapse } from "react-bootstrap";
import "font-awesome/scss/font-awesome.scss";

type Props = {
  question: string;
  answer: string;
  image: string | undefined;
  id: string;
};

const CollapsePanel = (props: Props) => {
  const { question, answer, id } = props;
  const [open, setOpen] = useState(false);
  const image = props.image
    ? require("../Content/images/sample.png").default
    : undefined;

  //const deptSelected: DepartmentDetail = {} as DepartmentDetail;
  // Second Argument Not provided: Runs after EVERY rendering
  // An empty array []: the side-effect runs once after the initial rendering
  // Has props or state values [prop1, prop2, ..., state1, state2]: the side-effect runs only when any depenendecy value changes.
  useEffect(() => {
    // Side-effect...

    return function cleanup() {
      // Side-effect cleanup...
    };
  }, [open]);

  return (
    <div className="faq-group">
      <div
        className="myheader animate"
        onClick={() => setOpen(!open)}
        aria-expanded={open}
        aria-controls={id}
      >
        <span className="myheaderIcon">
          <i className={`fa  ${open ? "fa-minus" : "fa-plus"}`}></i>
        </span>
        <span className="myheaderText">{parse(question)}</span>
      </div>
      <div style={{ height: "15px" }}> </div>
      <Collapse in={open}>
        <div id={id}>
          {parse(answer)}

          {image && (
            <div>
              <a rel="noreferrer" target="_blank" href={image}>
                <img src={image} alt="user" className="imglist" />
              </a>
            </div>
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default CollapsePanel;
