import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../store";
import FrequentlyAskQuestions from "../../frequentlyAskQuestions/FrequentlyAskQuestions";

type Props = PropsFromRedux & {};

const FAQ = (props: Props) => {
  return (
    <div className="PageWrapper pagelayout">
      <FrequentlyAskQuestions />
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  return {};
};

const mapDispatch = {};

const connector = connect(mapStateToProps, mapDispatch);

export default connector(FAQ);
