import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store";
import { useLocation } from "react-router-dom";
import {
  UserDepartments,
  UserPreferences,
} from "../../apis/dto/UserPreferences";
import { Assistant } from "../../apis/dto/People";
import "./NonEditPersonalInfo.scss";
import { LinkRowValue } from "./subcomponents/LinkRowValue";
import { useState } from "react";

type Props = PropsFromRedux & {
  userPreferences: UserPreferences;
  backUrl: string;
};

type DepartmentRow = {
  departmentTitle: string;
  workingTitle: string;
  url: string;
};

const NonEditPersonalInfo = (props: Props) => {
  const location = useLocation();
  const { userPreferences, backUrl } = props;

  const [isVisibleLegalName, setIsVisibleLegalName] = useState(false);

  const rowValues = (params: string[]) =>
    params.map((v) => {
      return (
        <div key={v} className="TableRow-value">
          {v}
        </div>
      );
    });

  const SimpleUserRow = (label: any, ...rest: any) => {
    return (
      <div className="TableRow TableRow-nonedit">
        <div className="TableRow-label">{label}</div>
        {rowValues(rest)}
      </div>
    );
  };

  const linkRowValues = (params: DepartmentRow[]) => {
    return params.map((v) => {
      return (
        <LinkRowValue
          key={v.departmentTitle}
          departmentTitle={v.departmentTitle}
          url={v.url}
          workingTitle={v.workingTitle}
        />
      );
    });
  };

  const linkUserRow = (label: string, values: DepartmentRow[]) => {
    if (!values.length) return null;
    return (
      <div className="TableRow TableRow-nonedit">
        <div className="TableRow-label">{label}</div>
        {linkRowValues(values)}
      </div>
    );
  };

  const deptAdminLinks = () =>
    userPreferences.deptAdmins.map((dept, index) => {
      return (
        <span key={dept.ucmnetid}>
          <a href={`mailto:${dept.email}`}>{`${dept.firstName} ${dept.lastName}`}</a>{" "}
          {userPreferences.deptAdmins.length - (index + 1) ? ", " : ""}
        </span>
      );
    });

  const contactDeptAdmins = () => {
    if (userPreferences.deptAdmins && userPreferences.deptAdmins.length > 0) {
      return (<p className="caption">
        <span>
          To correct the non editable information above, please contact{" "}
        </span>
        {deptAdminLinks()}
      </p>
      )
    } else {
      return (<p className="caption">
        <span>
          To correct the non editable information above, please contact Department Admins
        </span>
      </p>
      )
    }
  }

  const execAssistants = (assistants: Assistant[]) => {
    return assistants.map((assistant) => {
      return (
        <span key={assistant.ucmNetId}>
          {SimpleUserRow(`${assistant.assistantType} Assistant`, assistant.name)}
        </span>
      );
    });
  };

  const primaryDept =
    userPreferences.departments.find((dept) => dept.primary) ??
    ({} as UserDepartments);

  const isAdminPreferences = location.pathname.includes("/adminPreferences");

  const legalName =
    isVisibleLegalName &&
    (userPreferences.lastName || userPreferences.firstName)
      ? userPreferences.lastName + ", " + userPreferences.firstName
      : "xxxxxxxxxx";
  return (
    <div className="NonEditableInfoBox">
      <div className="NonEditableInfoBox-label">Non Editable Information</div>
      {isAdminPreferences && SimpleUserRow("Name", userPreferences.displayName)}
      {!isAdminPreferences &&
        (userPreferences.lastName || userPreferences.firstName) &&
        SimpleUserRow("Legal Name", legalName)}
      {SimpleUserRow("Email", userPreferences.email)}
      {userPreferences.departments &&
        userPreferences.departments.length > 0 &&
        linkUserRow("Primary Department", [
          {
            departmentTitle: primaryDept.departmentTitle,
            workingTitle: primaryDept.workingTitle,
            url: `/departmentPreferences/${primaryDept.departmentCode}?back=${backUrl}`,
          },
        ])}
      {userPreferences.departments &&
        linkUserRow(
          "Other Departments",
          [...userPreferences.departments]
            .filter((dept) => !dept.primary)
            ?.map((department) => {
              return {
                departmentTitle: department.departmentTitle,
                workingTitle: department.workingTitle,
                url: `/departmentPreferences/${department.departmentCode}?back=${backUrl}`,
              };
            })
        )}
      {userPreferences.mid && SimpleUserRow("M.ID", userPreferences.mid)}
      {userPreferences.employeeId &&
        SimpleUserRow("Employee ID", userPreferences.employeeId)}
      {userPreferences.assistants &&
        userPreferences.assistants.length > 0 &&
        execAssistants(userPreferences.assistants)}
      {contactDeptAdmins()}
      {!isAdminPreferences &&
        (userPreferences.lastName || userPreferences.firstName) && (
          <p>
            <label className="Checkbox">
              <input
                type="checkbox"
                name="displayLegalName"
                className="Checkbox-input"
                onClick={() => setIsVisibleLegalName(!isVisibleLegalName)}
              />
              Show Legal Name
            </label>
          </p>
        )}
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  return {};
};

const mapDispatch = {};

const connector = connect(mapStateToProps, mapDispatch);

export default connector(NonEditPersonalInfo);
