import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type GlobalState = { isSaving: boolean };

const defaultState: GlobalState = { isSaving: false };

const globalStateSlice = createSlice({
  name: "globalState",
  initialState: defaultState,
  reducers: {
    setIsSaving: (state, action: PayloadAction<boolean>) => {
      state.isSaving = action.payload;
    },
  },
});

export const { setIsSaving } = globalStateSlice.actions;
export default globalStateSlice.reducer;
