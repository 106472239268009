import React, { useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store";
import { setSearchQuery, UPDATE_FORM_STATE } from "../../features/peopleSearch";
import {
  Form,
  Field,
  FormProps,
  FieldRenderProps,
  FormSpy,
} from "react-final-form";
import createDecorator from "final-form-focus";
import "./searchBar.scss";
import { FormApi } from "final-form";

// Add external input props to this Component below
type Props = PropsFromRedux & {
  onSearchSubmit: Function;
};

const focusOnErrors = createDecorator();

const SearchBar = React.forwardRef((props: FormProps<Props>, ref: any) => {
  const { onSearchSubmit, setSearchQuery } = props;

  const FormStateToRedux = ({ form }: any) => {
    return (
      <FormSpy
        //subscribe when to update
        subscription={{ values: true }}
        onChange={(state) => props.UPDATE_FORM_STATE({ form, state })}
      />
    );
  };

  const onSubmit = async (values: any) => {
    onSearchSubmit();
    setSearchQuery(values.searchText);
  };

  type FieldInputProps = FieldRenderProps<string, HTMLElement>;

  const renderInput: React.FC<FieldInputProps> = (
    formProps: FieldInputProps
  ) => {
    const { input, ...rest } = formProps;
    return <input {...input} autoComplete="off" {...rest} />;
  };

  const formRef: React.MutableRefObject<FormApi | null> = useRef(null);

  const setSearchTextForm = () => {
    formRef.current?.mutators.setSearchTextField();
  };

  ref.current = setSearchTextForm;

  return (
    <Form
      onSubmit={onSubmit}
      decorators={[focusOnErrors]}
      //subscribe to form state and to determine these see that they are use below e.g.
      // {({ handleSubmit, form, submitting, pristine, values }) => {
      // this should be either same or a subset of the below
      subscription={{ submitting: true, pristine: true, values: true }}
      mutators={{
        setSearchTextField: (args, state, { setIn, changeValue }) => {
          const field = state.fields["searchText"];
          field.change("");
        },
      }}
      validate={(formValues) => {
        return {};
      }}
      render={({ handleSubmit, form, submitting, pristine, values }) => {
        formRef.current = form;
        return (
          <form
            name="searchBarForm"
            onSubmit={handleSubmit}
            className="SearchForm"
          >
            <FormStateToRedux form="searchBarForm" />
            <Field
              name="searchText"
              component={renderInput}
              className="SearchForm-input"
              placeholder="Search all faculty, staff, and students"
            />

            <button
              type="submit"
              id="searchButton"
              className="SearchForm-submit"
              disabled={submitting}
            >
              Submit
            </button>
          </form>
        );
      }}
    />
  );
});

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  return {
    departments: state.departments,
  };
};

const mapDispatch = {
  setSearchQuery,
  UPDATE_FORM_STATE,
};

const connector = connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
});

export default connector(SearchBar);
