import { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store";
import PeopleCard from "./peopleCard/PeopleCard";
import {
  fetchPeople,
  setIsDirty,
  setShowDepartmentList,
} from "../../features/peopleSearch";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingWheel from "../loadingWheel/LoadingWheel";
import { useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";

type Props = PropsFromRedux & {
  clearSearchBarText: Function;
};

const PeopleSearchResults = (props: Props) => {
  const { deptCode } = useParams();

  const {
    searchQuery,
    peopleResults,
    totalRecordCount,
    currentPage,
    hasMoreData,
    isDirty,
    setIsDirty,
    fetchPeople,
    setShowDepartmentList,
    loading,
    clearSearchBarText,
    loggedIn,
    error,
    PhotoLoadingError,
  } = props;

  // Second Argument Not provided: Runs after EVERY rendering
  // An empty array []: the side-effect runs once after the initial rendering
  // Has props or state values [prop1, prop2, ..., state1, state2]: the side-effect runs only when any depenendecy value changes.
  useEffect(() => {
    if (
      (searchQuery && searchQuery.length > 0 && isDirty) ||
      (deptCode && isDirty)
    ) {
      fetchPeople({
        deptCode: deptCode as string,
        searchText: searchQuery,
        pageNo: 0,
        pageSize: 20,
      });
      setIsDirty(false);
    }
  }, [searchQuery, isDirty]);

  useEffect(() => {
    if (error) toastr.error(error.title, error.message);
  }, [error]);

  useEffect(() => {
    if (PhotoLoadingError)
      toastr.error(PhotoLoadingError.title, PhotoLoadingError.message);
  }, [PhotoLoadingError]);

  const fetchMoreData = () => {
    fetchPeople({
      deptCode: deptCode as string,
      searchText: searchQuery,
      pageNo: currentPage + 1,
      pageSize: 20,
    });
  };

  const renderCards = peopleResults.map((person) => {
    return (
      <PeopleCard
        key={person.ucmnetid}
        person={person}
        permaLinkSingleResult={false}
      />
    );
  });
  const SignInFlag = () => {
    //TODO: implement if user is not signed in
    if (!loggedIn && loading !== "pending" && !deptCode) {
      return (
        <a
          href={process.env.REACT_APP_SIGN_IN_URL as string}
          className="SignInFlag"
        >
          Sign in to view students in search results.
        </a>
      );
    }
  };
  return (
    <div className="Results">
      <div className="Results-header">
        {loading !== "pending" && (
          <h1 className="Results-header-title">
            {totalRecordCount} Results {searchQuery && `for ‘${searchQuery}’`}
          </h1>
        )}
        {searchQuery && loading !== "pending" && (
          <a
            onClick={() => {
              clearSearchBarText();
              if (deptCode) {
                setIsDirty(true);
                setShowDepartmentList(true);
              } else {
                setShowDepartmentList(true);
              }
            }}
            className="pointer"
          >
            Clear Results
          </a>
        )}
      </div>

      {SignInFlag()}

      <div id="scrollableDiv" className="Results-results">
        <InfiniteScroll
          dataLength={peopleResults.length}
          next={fetchMoreData}
          hasMore={hasMoreData}
          loader={
            <LoadingWheel
              numberOfDivs={4}
              showLoading={loading === "pending"}
              spinnerOnly={false}
            />
          }
          style={{ overflow: "visible" }}
        >
          {renderCards}
        </InfiniteScroll>
      </div>

      {totalRecordCount > 4 && SignInFlag()}
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  const {
    searchQuery,
    entities,
    isDirty,
    totalRecordCount,
    numPages,
    currentPage,
    hasMoreData,
    loading,
    error,
    PhotoLoadingError,
  } = state.people;
  const { loggedIn } = state.auth;
  return {
    searchQuery,
    peopleResults: entities,
    isDirty,
    totalRecordCount,
    numPages,
    currentPage,
    hasMoreData,
    loading,
    loggedIn,
    error,
    PhotoLoadingError,
  };
};

const mapDispatch = {
  fetchPeople,
  setIsDirty,
  setShowDepartmentList,
};

const connector = connect(mapStateToProps, mapDispatch);

export default connector(PeopleSearchResults);
