import ucmercedsealwhite200 from "../Content/images/static/566f601fa62308919911a23ba07d9c9c/69585/uc-merced-seal-white.png";
import ucmercedsealwhite400 from "../Content/images/static/566f601fa62308919911a23ba07d9c9c/497c6/uc-merced-seal-white.png";
import ucmercedsealwhite800D from "../Content/images/static/566f601fa62308919911a23ba07d9c9c/ee604/uc-merced-seal-white.png";
import ucmercedsealwhite1200 from "../Content/images/static/566f601fa62308919911a23ba07d9c9c/f3583/uc-merced-seal-white.png";
import ucmercedsealwhite1600 from "../Content/images/static/566f601fa62308919911a23ba07d9c9c/5707d/uc-merced-seal-white.png";
import ucmercedsealwhite1906 from "../Content/images/static/566f601fa62308919911a23ba07d9c9c/3a9a1/uc-merced-seal-white.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="Footer">
      <div className="WatermarkSeal">
        <img
          src={ucmercedsealwhite800D}
          srcSet={`${ucmercedsealwhite200} 200w, ${ucmercedsealwhite400} 400w, ${ucmercedsealwhite800D} 800w, ${ucmercedsealwhite1200} 1200w, ${ucmercedsealwhite1600} 1600w, ${ucmercedsealwhite1906} 1906w`}
          sizes="(max-width: 800px) 100vw, 800px"
          alt="UC Merced Seal"
        />
      </div>
      <Link to="/faq" className="Footer-faqBanner">
        <div className="PageWrapper">
          Questions about the Directory? Read the FAQ’s →
        </div>
      </Link>
      <div className="Footer-content">
        <div className="PageWrapper">
          <div className="Footer-disclaimer">
            Privacy Notice: This campus directory is the property of the
            University of California, Merced. To protect the privacy of
            individuals listed herein, in accordance with the State of
            California Information Practices Act, this directory may not be
            used, rented, distributed, or sold for commercial purposes. For more
            details, please see the{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://policy.ucop.edu/doc/7020462/BFB-RMP-7"
              className="Footer-disclaimer-link"
            >
              university guidelines
            </a>
            &nbsp; in mailing lists and telephone directories. If you have any
            questions, please contact the &nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://it.ucmerced.edu/service-desk"
              className="Footer-disclaimer-link"
            >
              OIT Service Desk
            </a>
            .
          </div>
          <div className="Footer-legal">
            <div className="Footer-legal-item">
              <a
                target="_blank"
                rel="noreferrer"
                href="http://regents.universityofcalifornia.edu"
                className="Footer-legal-link"
              >
                © 2022 UC Regents
              </a>
            </div>
            <div className="Footer-legal-item">
              <a
                target="_blank"
                rel="noreferrer"
                href="http://accreditation.ucmerced.edu/"
                className="Footer-legal-link"
              >
                Accreditation
              </a>
            </div>
            <div className="Footer-legal-item">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.ucmerced.edu/privacy-statement"
                className="Footer-legal-link"
              >
                Privacy/Legal
              </a>
            </div>
            <div className="Footer-legal-item">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.ucmerced.edu/about-this-site"
                className="Footer-legal-link"
              >
                About This Site
              </a>
            </div>
            <div className="Footer-legal-item">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.ucmerced.edu/Site-List-A-F"
                className="Footer-legal-link"
              >
                Site List
              </a>
            </div>
            <div className="Footer-legal-item">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://emergency.ucmerced.edu/"
                aria-describedby="audioeye_new_window_message"
                className="Footer-legal-link"
              >
                Emergency Preparedness
              </a>
            </div>
            <div className="Footer-legal-item">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.ucmerced.edu/right-to-know"
                className="Footer-legal-link"
              >
                Your Right to Know
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
