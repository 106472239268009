import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store";
import LoadingWheel from "../loadingWheel/LoadingWheel";
import { DepartmentDetail } from "../../apis/dto/DepartmentDetail";
import {
  fetchDepartmentByDeptCode,
  fetchBuildings,
  updateDepartmentPreferences,
} from "../../features/departments";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { setShowDepartmentList } from "../../features/peopleSearch";
import { Form, Field, FieldRenderProps, FormSpy } from "react-final-form";
import createDecorator from "final-form-focus";
import { useNavigate } from "react-router-dom";
import DepartmentAdmins from "../departmentAdmins/DepartmentAdmins";
import { toastr } from "react-redux-toastr";
import { setIsSaving } from "../../features/globalStateReducer";

type Props = PropsFromRedux & {};

const focusOnErrors = createDecorator();

const DepartmentInfo = (props: Props) => {
  const navigate = useNavigate();
  const { deptCode } = useParams();
  const {
    departmentDetails,
    error,
    loading,
    fetchDepartmentByDeptCode,
    fetchBuildings,
    buildingsState,
    updateDepartmentPreferences,
    setIsSaving,
  } = props;

  // Second Argument Not provided: Runs after EVERY rendering
  // An empty array []: the side-effect runs once after the initial rendering
  // Has props or state values [prop1, prop2, ..., state1, state2]: the side-effect runs only when any depenendecy value changes.
  useEffect(() => {
    if (error) {
      toastr.error(error.title, error.message);
    }
    if (
      buildingsState &&
      buildingsState.entities &&
      buildingsState.entities.length < 1 &&
      buildingsState.loading === "idle"
    ) {
      fetchBuildings();
    }
    if (
      deptCode &&
      !(deptSelected && Object.keys(deptSelected).length > 0) &&
      loading === "idle"
    ) {
      fetchDepartmentByDeptCode(deptCode);
    }
    return function cleanup() {
      // Side-effect cleanup...
    };
  }, [props.departmentDetails, props.buildingsState, error]);

  const deptSelected: DepartmentDetail =
    departmentDetails.find((dept) => dept.deptCode === deptCode) ??
    ({} as DepartmentDetail);

  const onSubmit = async (values: any) => {
    updateDepartmentPreferences(values).then((_) => {
      setIsSaving(true);
      setTimeout(() => setIsSaving(false), 3000);
    });
  };

  type FieldInputProps = FieldRenderProps<string, HTMLElement>;

  const renderInput: React.FC<FieldInputProps> = (
    formProps: FieldInputProps
  ) => {
    const { input, label, ...rest } = formProps;
    return (
      <div className="InputLabelPair">
        <label className="InputLabelPair-label">{label}</label>
        <input
          {...input}
          type="text"
          autoComplete="off"
          {...rest}
          className="InputLabelPair-textInput"
        />
      </div>
    );
  };

  const renderTextArea: React.FC<FieldInputProps> = (
    formProps: FieldInputProps
  ) => {
    const { input, label, ...rest } = formProps;

    return (
      <div className="InputLabelPair">
        <label className="InputLabelPair-label">{label}</label>
        <textarea
          style={{ height: "auto" }}
          {...input}
          autoComplete="off"
          {...rest}
          className="InputLabelPair-textInput"
        ></textarea>
      </div>
    );
  };
  type Props = FieldRenderProps<string, HTMLElement>;

  const SelectInput: React.FC<Props> = ({
    input,
    label,
    meta,
    ...rest
  }: Props) => {
    //const {onchange, ...restofInput} = input;
    return (
      <div className="InputLabelPair">
        <label className="InputLabelPair-label">{label}</label>
        <select
          className="InputLabelPair-selectInput"
          {...input}
          {...rest}
        />
      </div>
    );
  };
  const buildings = () =>
    buildingsState.entities.map((building) => {
      return (
        <option key={building.code} value={building.code}>
          {building.name}{" "}
        </option>
      );
    });
  const rooms = (buildingCode: string) =>
    buildingsState.entities
      .find((b) => b.code === buildingCode)
      ?.rooms.map((r) => {
        return (
          <option key={r.code} value={r.code}>
            {r.name}{" "}
          </option>
        );
      });
  return (
    <React.Fragment>
      <div className="AdminPreferencesLayout-personalInfo">
        <div className="DepartmentPreferences">
          {navigate.length > 0 && (
            <a href="#" onClick={() => navigate(-1)}>
              ← Back to My Preferences
            </a>
          )}
          <div>
            <h1 className="DepartmentPreferences-heading">
              Department Information
            </h1>
            <Link
              to={`/department/${deptCode}`}
              className="DepartmentPreferences-heading-link"
            >
              View Department Page
            </Link>
          </div>
          {(loading === "succeeded" || loading === "idle") && (
            <div>
              <div className="NonEditableInfoBox">
                <div className="NonEditableInfoBox-label">
                  Non Editable Information
                </div>
                <div className="TableRow">
                  <div className="TableRow-label">Dept Code</div>
                  <div className="TableRow-value">{deptSelected.deptCode}</div>
                </div>
                <div className="TableRow">
                  <div className="TableRow-label">Payroll Dept Name</div>
                  <div className="TableRow-value">
                    {deptSelected.payrollName}
                  </div>
                </div>
                <p className="caption">
                  To correct the non editable information above, please submit a
                  change request{" "}
                  <a
                    rel="noreferrer"
                    href="https://dfa.ucmerced.edu/content/coa-change-request"
                    target="_blank"
                  >
                    click here
                  </a>
                </p>
              </div>
              {loading === "idle" && buildingsState.loading === "idle" && (
                <Form
                  onSubmit={onSubmit}
                  decorators={[focusOnErrors]}
                  initialValues={deptSelected}
                  //subscribe to form state and to determine these see that they are use below e.g.
                  // {({ handleSubmit, form, submitting, pristine, values }) => {
                  // this should be either same or a subset of the below
                  subscription={{
                    submitting: true,
                    pristine: true,
                    values: true,
                  }}
                  mutators={{
                    getSelectedBuilding: (args, state, utils) => {
                    },
                  }}
                  validate={(formValues) => {
                    return {};
                  }}
                >
                  {({ handleSubmit, form, submitting, pristine }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <section>
                          <Field
                            name="name"
                            label="Name"
                            component={renderInput}
                          />
                          <Field
                            name="departmentEmail"
                            label="Email"
                            component={renderInput}
                          />
                          <Field
                            name="description"
                            label="Description"
                            component={renderTextArea}
                            rows="8"
                          />

                          <div className="MultiInputRow MultiInputRow--2inputs">
                            <div>
                              <Field
                                name="phoneNumber"
                                label="Phone Number"
                                component={renderInput}
                              />
                            </div>
                            <div>
                              <Field
                                name="faxNumber"
                                label="Fax"
                                component={renderInput}
                              />
                            </div>
                          </div>
                          <Field
                            name="address"
                            label="Address"
                            component={renderTextArea}
                            rows="3"
                          />
                          <Field
                            name="url"
                            label="Website"
                            component={renderInput}
                          />
                          <div className="MultiInputRow MultiInputRow--2inputs">
                            <FormSpy>
                              {(props) => (
                                <React.Fragment>
                                  <div>
                                    <Field<string>
                                      name="buildingCode"
                                      label="Building"
                                      component={SelectInput}
                                    >
                                      <option value="">
                                        please choose one
                                      </option>
                                      {buildings()}
                                    </Field>
                                  </div>
                                  <div>
                                    <Field<string>
                                      name="roomCode"
                                      label="Room"
                                      component={SelectInput}
                                    >
                                      <option value="">
                                        please choose one
                                      </option>
                                      {rooms(
                                        props.form.getState().values
                                          .buildingCode
                                      )}
                                    </Field>
                                  </div>
                                </React.Fragment>
                              )}
                            </FormSpy>
                          </div>
                          <div className="FormActions">
                            <button
                              className="Button Button--primary"
                              type="submit"
                              id="departmentPreferencesFormSubmit"
                              disabled={submitting}
                            >
                              Save Changes
                            </button>
                            <button
                              type="button"
                              className="Button Button--secondary"
                              onClick={form.reset}
                              disabled={submitting || pristine}
                              ng-click="$ctrl.goBack()"
                            >
                              Discard Changes
                            </button>
                          </div>
                        </section>
                      </form>
                    );
                  }}
                </Form>
              )}
            </div>
          )}
          <LoadingWheel
            spinnerOnly={false}
            numberOfDivs={4}
            showLoading={
              loading === "pending" || buildingsState.loading === "pending"
            }
          />
        </div>
      </div>
      <DepartmentAdmins
        departmentAdmins={deptSelected.admins}
        deptCode={deptSelected.deptCode}
      />
    </React.Fragment>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  const { entities, error, loading } = state.departments.departmentDetail;

  return {
    departmentDetails: entities,
    error,
    loading,
    buildingsState: state.departments.buildings,
  };
};

const mapDispatch = {
  fetchDepartmentByDeptCode,
  setShowDepartmentList,
  fetchBuildings,
  updateDepartmentPreferences,
  setIsSaving,
};

const connector = connect(mapStateToProps, mapDispatch);

export default connector(DepartmentInfo);
