import { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store";
import { fetchCurrentUser, setAlreadyExecuted } from "../../features/auth";
import ucmercedlogo1x from "../Content/images/UCM_Logos_Primary_120.png";
import ucmercedlogo1p5x from "../Content/images/UCM_Logos_Primary_180.png";
import ucmercedlogo2x from "../Content/images/UCM_Logos_Primary_240.png";
import { Link } from "react-router-dom";
import { toastr } from "react-redux-toastr";

type Props = PropsFromRedux & {};

const Header = (props: Props) => {
  const {
    deptAdmin,
    displayName,
    loggedIn,
    student,
    username,
    error,
    alreadyExecuted,
    setAlreadyExecuted,
    fetchCurrentUser,
  } = props;

  // Second Argument Not provided: Runs after EVERY rendering
  // An empty array []: the side-effect runs once after the initial rendering
  // Has props or state values [prop1, prop2, ..., state1, state2]: the side-effect runs only when any depenendecy value changes.
  useEffect(() => {
    if (error) toastr.error(error.title, error.message);

    if (!alreadyExecuted) {
      setAlreadyExecuted(true);
      fetchCurrentUser();
    }
  }, [error]);

  return (
    <div className="Header">
      <div className="PageWrapper">
        <div className="Header-content">
          <span className="Header-masthead">
            <a href="https://www.ucmerced.edu">
              <img
                className="Header-masthead-seal"
                src={ucmercedlogo1x}
                srcSet={`${ucmercedlogo1x} 1x, ${ucmercedlogo1p5x} 1.5x, ${ucmercedlogo2x} 2x`}
                alt="UC Merced Seal"
              />
            </a>
            <a href="/" className="Header-masthead-title">
              <h1 className="Header-masthead-title-heading">
                <div className="Header-masthead-title-heading-firstLine">
                  University of California Merced
                </div>
                <div className="Header-masthead-title-heading-lastLine">
                  Directory
                </div>
              </h1>
            </a>
          </span>
          <div className="Header-links">
            <div>
              <div>
                {loggedIn && displayName && <span> {displayName} </span>}
                {!loggedIn && (
                  <a
                    href={process.env.REACT_APP_SIGN_IN_URL as string}
                    className="Header-links-signInOut"
                  >
                    Sign in
                  </a>
                )}
                {loggedIn && (
                  <a
                    href={process.env.REACT_APP_SIGN_OUT_URL as string}
                    className="Header-links-signInOut"
                  >
                    Sign Out
                  </a>
                )}
                {loggedIn && student && (
                  <Link
                    to={`/studentPreferences/${username}`}
                    className="EditButton"
                  >
                    Edit Info
                  </Link>
                )}
                {loggedIn && deptAdmin && (
                  <Link
                    to={`/adminPreferences/${username}`}
                    className="EditButton"
                  >
                    Edit Info
                  </Link>
                )}
                {loggedIn && !student && !deptAdmin && (
                  <Link to={`/myPreferences`} className="EditButton">
                    Edit Info
                  </Link>
                )}
              </div>
              <div>
                <Link to="/faq">Directory FAQ</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  const {
    deptAdmin,
    displayName,
    loggedIn,
    student,
    username,
    loading,
    error,
    alreadyExecuted,
  } = state.auth;
  return {
    deptAdmin,
    displayName,
    loggedIn,
    student,
    username,
    loading,
    error,
    alreadyExecuted,
  };
};

const mapDispatch = {
  fetchCurrentUser,
  setAlreadyExecuted,
};

const connector = connect(mapStateToProps, mapDispatch);

export default connector(Header);
