import { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store";
import {
  fetchAssistantSearch,
  resetSearchResults,
  setLoading,
} from "../../features/userPreferences";
import LoadingWheel from "../loadingWheel/LoadingWheel";
import useDebounce from "../../hooks/useDebounce";
import { toastr } from "react-redux-toastr";

type Props = PropsFromRedux & {
  bossId: string;
  callBackForAssistantSelectedToAdd: Function;
};

const AssistantSearch = (props: Props) => {
  const {
    fetchAssistantSearch,
    error,
    loading,
    assistants,
    resetSearchResults,
    bossId,
    setLoading,
    callBackForAssistantSelectedToAdd,
  } = props;
  const [debouncedName, setDebouncedName] = useDebounce(500, "");
  const [queryName, setQueryName] = useState("");

  //const deptSelected: DepartmentDetail = {} as DepartmentDetail;
  // Second Argument Not provided: Runs after EVERY rendering
  // An empty array []: the side-effect runs once after the initial rendering
  // Has props or state values [prop1, prop2, ..., state1, state2]: the side-effect runs only when any depenendecy value changes.
  useEffect(() => {
    if (queryName && queryName.length > 1) {
      fetchAssistantSearch({ searchTerm: queryName, bossId: bossId });
    } else {
      resetSearchResults(undefined);
    }
    if (error) {
      toastr.error(error.title, error.message);
    }
    return function cleanup() {
      // Side-effect cleanup...
    };
  }, [debouncedName, error]);

  const handleSearch = (e: any) => {
    setLoading("idle");
    setQueryName(e.target.value);
    setDebouncedName(e.target.value);
  };

  const searchResults = () =>
    assistants.map((assitant) => {
      return (
        <div
          key={assitant.accountId}
          className="MyPreferences-assistant-search-results"
        >
          <div className="MyPreferences-assistant-search-results-row">
            <div className="MyPreferences-assistant-search-results-row-result">
              <div className="MyPreferences-assistant-search-results-row-result-name">
                {assitant.sortableName}
              </div>
              <div>{assitant.username}</div>
              <div className="MyPreferences-assistant-search-results-row-result-title">
                {assitant.title}
              </div>
            </div>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                callBackForAssistantSelectedToAdd(assitant);
                resetSearchResults(undefined);
                setQueryName("");
              }}
              className="pointer"
            >
              Add
            </a>
          </div>
        </div>
      );
    });
  return (
    <div className="MyPreferences-assistant-search">
      <div className="InputLabelPair">
        <label className="InputLabelPair-label" htmlFor="assistant">
          Add Assistant
        </label>
        <input
          type="text"
          id="add-assistant"
          placeholder="UCMNetId"
          value={queryName}
          onChange={handleSearch}
          className="InputLabelPair-textInput"
        />
      </div>
      {searchResults()}
      <LoadingWheel
        numberOfDivs={4}
        showLoading={loading === "pending"}
        spinnerOnly={false}
      />
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  const { error, loading, entities } = state.userPreferences.AssistantSearch;
  return {
    error,
    loading,
    assistants: entities,
  };
};

const mapDispatch = {
  fetchAssistantSearch,
  resetSearchResults,
  setLoading,
};

const connector = connect(mapStateToProps, mapDispatch);

export default connector(AssistantSearch);
