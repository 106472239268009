import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { CurrentUser } from "../apis/dto/auth";
import idm from "../apis/idm";
import { ErrorToastr } from "../components/utility/ErrorToastr";

export const fetchCurrentUser = createAsyncThunk<CurrentUser, void>(
  "authSearch",
  async (_, thunkApi) => {
    try {
      const response = await idm.get(`/directory/currentUser`, {
        withCredentials: true,
      });

      if (typeof response.data == "object") {
        return response.data as CurrentUser;
      } else {
        throw new Error("Error - " + response.data);
      }
    } catch (err: any) {
      let error: AxiosError = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      const meta = { errorMessage: err.message, status: error.response.status };
      const response = { responseMessage: error.response.data, meta: meta };
      return thunkApi.rejectWithValue(response);
    }
  }
);

interface authState extends CurrentUser {
  alreadyExecuted: boolean;
  error: ErrorToastr | null | undefined;
  loading: "idle" | "pending" | "succeeded" | "failed";
}

const setObjectValuesinState = (state: any, inputProps: any) => {
  for (const prop in state) {
    if (inputProps[prop]) {
      state[prop] = inputProps[prop];
    }
  }
};
const authSlice = createSlice({
  name: "auth",
  initialState: {
    deptAdmin: false,
    displayName: null,
    loggedIn: false,
    student: false,
    username: null,
    loading: "idle",
    error: null,
    alreadyExecuted: false,
  } as authState,

  reducers: {
    setAlreadyExecuted: (state, action) => {
      state.alreadyExecuted = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentUser.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
        }
      })
      // Add reducers for additional action types here, and handle loading state as needed
      .addCase(
        fetchCurrentUser.fulfilled,
        (state, action: PayloadAction<CurrentUser>) => {
          setObjectValuesinState(state, action.payload);
          state.loading = "succeeded";
        }
      )
      .addCase(fetchCurrentUser.rejected, (state: any, action: any) => {
        if (action.payload) {
          const errorObj: ErrorToastr = {
            title: "!Error - " + action.payload.meta.status,
            message:
              action.payload && action.payload.responseMessage.message
                ? action.payload.responseMessage.message
                : action.payload.meta.errorMessage,
          };
          state.error = errorObj;
        } else {
          const errorObj: ErrorToastr = {
            title: "!Unknown Error",
            message: "Contact Help Desk - " + action.error.message,
          };
          state.error = errorObj;
        }

        state.loading = "failed";
      });
  },
});

export const { setAlreadyExecuted } = authSlice.actions;
export default authSlice.reducer;
