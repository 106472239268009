import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { People, PeoplePagingResult } from "../apis/dto/People";
import idm from "../apis/idm";
import { ErrorToastr } from "../components/utility/ErrorToastr";

interface pagingSearch {
  deptCode: string;
  searchText: string;
  pageNo: number;
  pageSize: number;
}

export const fetchPerson = createAsyncThunk<People, string>(
  "personSearch",
  async (ucmnetid: string, thunkApi) => {
    try {
      const response = await idm.get(`/directory/person/${ucmnetid}`, {
        withCredentials: true,
      });

      if (typeof response.data === "object") {
        return response.data as People;
      } else {
        throw new Error("Error - " + response.data);
      }
    } catch (err: any) {
      let error: AxiosError = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      const meta = { errorMessage: err.message, status: error.response.status };
      const response = { responseMessage: error.response.data, meta: meta };
      return thunkApi.rejectWithValue(response);
    }
  }
);

export const fetchPeople = createAsyncThunk<PeoplePagingResult, pagingSearch>(
  "peopleSearch",
  async (
    searchCiteria: pagingSearch = {
      deptCode: "",
      searchText: "",
      pageNo: 0,
      pageSize: 20,
    },
    thunkApi
  ) => {
    try {
      const { deptCode, searchText, pageNo, pageSize } = searchCiteria;
      const searchTextDeptHack =
        deptCode && searchText.length === 0 ? " " : searchText;
      const url =
        deptCode && deptCode.length > 0
          ? `/directory/matchEntries/OS/${encodeURIComponent(
              searchTextDeptHack
            )}/${deptCode}/${pageNo}/${pageSize}`
          : `/directory/matchEntries/OS/${encodeURIComponent(
              searchTextDeptHack
            )}/${pageNo}/${pageSize}`;

      const response = await idm.get(url, {
        withCredentials: true,
      });

      if (typeof response.data === "object") {
        return response.data as PeoplePagingResult;
      } else {
        throw new Error("Error - " + response.data);
      }
    } catch (err: any) {
      let error: AxiosError = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      const meta = { errorMessage: err.message, status: error.response.status };
      const response = { responseMessage: error.response.data, meta: meta };
      return thunkApi.rejectWithValue(response);
    }
  }
);

interface PhotoResponse {
  response: any;
  person: People;
}
export const fetchPhoto = createAsyncThunk<PhotoResponse, People>(
  "photoFetch",
  async (person: People, thunkApi) => {
    try {
      if (person.photoUrl === null) {
        return thunkApi.rejectWithValue("Photo URL is null");
      } else {
        const response = await idm.get(person.photoUrl, {
          responseType: "blob",
          withCredentials: person.localPhoto,
        });
        const responseData: PhotoResponse = {
          response: window.URL.createObjectURL(response.data),
          person: person,
        };
        return responseData;
      }
    } catch (err: any) {
      let error: AxiosError = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      const meta = { errorMessage: err.message, status: error.response.status };
      const response = { responseMessage: error.response.data, meta: meta };
      return thunkApi.rejectWithValue(response);
    }
  }
);

interface PeopleState {
  showDepartemntList: boolean;
  isDirty: boolean;
  currentPage: number;
  numPages: number;
  totalRecordCount: number;
  searchQuery: string;
  error: ErrorToastr | null | undefined;
  loading: "idle" | "pending" | "succeeded" | "failed";
  entities: People[];
  person: People | undefined;
  hasMoreData: boolean;
  searchBarForm?: any;
  PhotoLoadingError: ErrorToastr | null | undefined;
}

interface setPersonLoadingImages {
  person: People;
  isLoadingImages: "idle" | "pending" | "succeeded" | "failed";
}

const peopleSearchSlice = createSlice({
  name: "peopleSearch",
  initialState: {
    showDepartemntList: true,
    isDirty: false,
    currentPage: 0,
    numPages: 0,
    totalRecordCount: 0,
    searchQuery: "",
    entities: [],
    person: undefined,
    hasMoreData: false,
    loading: "idle",
    error: null,
    PhotoLoadingError: null,
  } as PeopleState,

  reducers: {
    setShowDepartmentList: (state, action: PayloadAction<boolean>) => {
      state.entities = [];
      state.currentPage = 0;
      state.totalRecordCount = 0;
      state.hasMoreData = false;
      state.searchQuery = "";
      state.showDepartemntList = action.payload;
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.entities = [];

      state.searchQuery = action.payload;
      state.isDirty = true;
    },
    setIsDirty: (state, action: PayloadAction<boolean>) => {
      state.isDirty = action.payload;
    },
    setPersonIsLoadImages: (
      state,
      action: PayloadAction<setPersonLoadingImages>
    ) => {
      const personToFind = action.payload.person;
      const isLoadingImages = action.payload.isLoadingImages;
      const personFound = state.entities.find(
        (p) => p.ucmnetid === personToFind.ucmnetid
      );
      if (personFound) {
        personFound.isLoadingImages = isLoadingImages;
      }
    },
    UPDATE_FORM_STATE: (state: any, action) => {
      state[action.payload.form] = action.payload.state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPeople.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
        }
      })
      // Add reducers for additional action types here, and handle loading state as needed
      .addCase(
        fetchPeople.fulfilled,
        (state, action: PayloadAction<PeoplePagingResult>) => {
          state.loading = "idle";
          state.currentPage = action.payload.currentPage;
          state.numPages = action.payload.numPages;
          state.totalRecordCount = action.payload.totalCount;
          state.hasMoreData = state.currentPage + 1 !== state.numPages;
          let data = action.payload.directoryEntries;
          data.forEach((item) => {
            item.isLoadingImages = "idle";
          });
          // Add user to the state array

          const existingIds = state.entities.map((x) => x.ucmnetid);
          const personsToAdd = data.filter(
            (x) => !existingIds.includes(x.ucmnetid)
          );
          state.entities.push(...personsToAdd);
        }
      )
      .addCase(fetchPeople.rejected, (state: any, action: any) => {
        if (action.payload) {
          const errorObj: ErrorToastr = {
            title: "!Error - " + action.payload.meta.status,
            message:
              action.payload && action.payload.responseMessage.message
                ? action.payload.responseMessage.message
                : action.payload.meta.errorMessage,
          };
          state.error = errorObj;
        } else {
          const errorObj: ErrorToastr = {
            title: "!Unknown Error",
            message: "Contact Help Desk - " + action.error.message,
          };
          state.error = errorObj;
        }

        state.loading = "failed";
      })
      .addCase(fetchPerson.pending, (state, action) => {
        //console.log('idle');

        if (state.loading === "idle") {
          state.loading = "pending";
        }
      })
      // Add reducers for additional action types here, and handle loading state as needed
      .addCase(
        fetchPerson.fulfilled,
        (state, action: PayloadAction<People>) => {
          state.loading = "idle";

          action.payload.isLoadingImages = "idle";
          // Add user to the state array
          state.person = action.payload;
        }
      )
      .addCase(fetchPerson.rejected, (state: any, action: any) => {
        if (action.payload) {
          const errorObj: ErrorToastr = {
            title: "!Error - " + action.payload.meta.status,
            message:
              action.payload && action.payload.responseMessage.message
                ? action.payload.responseMessage.message
                : action.payload.meta.errorMessage,
          };
          state.error = errorObj;
        } else {
          const errorObj: ErrorToastr = {
            title: "!Unknown Error",
            message: "Contact Help Desk - " + action.error.message,
          };
          state.error = errorObj;
        }

        state.loading = "failed";
      })
      .addCase(fetchPhoto.fulfilled, (state, action) => {
        const response = action.payload.response;
        const person = action.payload.person;
        const personFound = state.entities.find(
          (p) => p.ucmnetid === person.ucmnetid
        );
        if (personFound) {
          personFound.photo = response;
          personFound.isLoadingImages = "succeeded";
        } else if (state.person && state.person.ucmnetid === person.ucmnetid) {
          state.person.photo = response;
          state.person.isLoadingImages = "succeeded";
        }
      })
      .addCase(fetchPhoto.rejected, (state: any, action: any) => {
        const personFound = state.entities.find(
          (p: { ucmnetid: People }) => p.ucmnetid === action.meta.arg.ucmnetid
        );
        if (personFound) {
          personFound.isLoadingImages = "failed";
        }
        console.error("Error loading photo", action.payload);

        if (action.payload) {
          const errorObj: ErrorToastr = {
            title: "!Error - " + action.payload.meta.status,
            message:
              action.payload && action.payload.responseMessage.message
                ? action.payload.responseMessage.message
                : action.payload.meta.errorMessage,
          };
          state.PhotoLoadingError = errorObj;
        } else {
          const errorObj: ErrorToastr = {
            title: "!Unknown Error",
            message: "Contact Help Desk - " + action.error.message,
          };
          state.PhotoLoadingError = errorObj;
        }
      });
  },
});

export const selectForm = (state: any, form: any) => {
  return (state && state.people && state.people[form]) || {};
};

export const {
  setSearchQuery,
  setIsDirty,
  setShowDepartmentList,
  setPersonIsLoadImages,
  UPDATE_FORM_STATE,
} = peopleSearchSlice.actions;
export default peopleSearchSlice.reducer;
