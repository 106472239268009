import { Link } from "react-router-dom";

type Props = { departmentTitle: string; url: string; workingTitle: string };

export const LinkRowValue = (props: Props) => {
  const { departmentTitle, url, workingTitle } = props;
  return (
    <div key={departmentTitle} className="TableRow-value">
      <Link className="a_dept" to={url}>
        {departmentTitle}
      </Link>
      {workingTitle && <span>&#xfe63;</span>}
      {workingTitle}
    </div>
  );
};
