import { PeopleDepartment } from "../../../../apis/dto/People";

type DepartmentLabelsProps = { departments: PeopleDepartment[] };
type DepartmentLabelProps = { department: PeopleDepartment };
type DeptTitleProps = { label: string; isPrimary: boolean; italic?: boolean };

export const DeptTitle = (props: DeptTitleProps) => {
  const { label, italic = false, isPrimary } = props;
  const italicCSS = italic ? "italic" : "";
  const primaryCSS = isPrimary ? "ResultBlock-info-office" : "";
  return (
    <span className={`deptTitle ${italicCSS} ${primaryCSS}`}>{label}</span>
  );
};

export const DepartmentLabel = (props: DepartmentLabelProps) => {
  const {
    department: { workingTitle, departmentTitle, primary },
  } = props;

  const getWorkingTitle = () => {
    if (!workingTitle) return null;
    return (
      <DeptTitle
        label={`${workingTitle}, `}
        italic={true}
        isPrimary={primary}
      />
    );
  };

  return (
    <div className="deptTitle">
      {getWorkingTitle()}
      <DeptTitle label={departmentTitle} isPrimary={primary} />
    </div>
  );
};

export const DepartmentLabels = (props: DepartmentLabelsProps) => {
  const { departments } = props;
  const primaryDepartments = departments.filter((dep) => dep.primary);
  const labels = primaryDepartments.map((dep) => {
    return <DepartmentLabel key={dep.departmentCode} department={dep} />;
  });
  return <div>{labels}</div>;
};
