import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store";
import {
  fetchDepartments,
} from "../../features/departments";
import { Link } from "react-router-dom";
import { Department } from "../../apis/dto/Department";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./DepartmentList.scss";
import { toastr } from "react-redux-toastr";

interface AppState {
  fetching: boolean;
  departmentFilter: string;
  showList: boolean;
  highlightedHobby: boolean;
  updatingTransition: boolean;
}

type Props = PropsFromRedux;

class DepartmentList extends React.Component<Props, AppState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      fetching: false,
      departmentFilter: "",
      showList: false,
      highlightedHobby: false,
      updatingTransition: false,
    };
  }

  componentDidUpdate() {
    const { error } = this.props.departments;

    if (error) {
      toastr.error(error.title, error.message);
    }
  }

  componentDidMount() {
    if (
      !this.props.departments.entities ||
      this.props.departments.entities.length < 1
    ) {
      this.props.fetchDepartments();
    }
  }

  renderDepartmentList(): JSX.Element[] {

    return this.props.departments.entities
      .map<Department>((dept) => Object.values(dept)[0])
      .filter((x) =>
        x.name.toLowerCase().match(this.state.departmentFilter.toLowerCase())
      )
      .map((dept: Department) => {
        return (
          <CSSTransition
            key={dept.deptCode}
            timeout={300}
            classNames="animate-repeat"
          >
            <div className="Index-departmentList-department">
              <Link
                to={`department/${dept.deptCode}`}
                className="departmentList"
                title={dept.name}
              >
                {dept.name}
              </Link>
            </div>
          </CSSTransition>
        );
      });
  }

  onUpdateDepartmentFilter = (event: any) => {
    this.setState({
      departmentFilter: event.target.value,
      updatingTransition: !this.state.updatingTransition,
    });
  };

  switch = () => {
    this.setState((prevState) => ({
      showList: !prevState.showList,
    }));
  };

  listSwitch = () => {
    this.setState((state) => ({
      highlightedHobby: !state.highlightedHobby,
    }));
  };
  renderTest() {
    return (
      <div className="container">
        <button className="display" onClick={this.switch}>
          Obinna
        </button>
        <CSSTransition
          in={this.state.showList}
          timeout={400}
          classNames="list-transition"
          unmountOnExit
          appear
          onEntered={this.listSwitch}
          onExit={this.listSwitch}
        >
          <div className="list-body">
            <ul className="list">
              <li className="list-item"> Running</li>
              <li className="list-item"> Technical Writing</li>
              <li className="list-item"> Writing Clean code</li>
            </ul>
          </div>
        </CSSTransition>
      </div>
    );
  }
  render() {
    return (
      <div>
        {/* {this.renderTest()} */}
        <div className="Index-departmentHeader">
          <div className="Index-departmentHeader-heading">
            Search by Department
          </div>
          <div className="Index-departmentHeader-filter">
            <label className="Index-departmentHeader-filter-label">
              Filter
            </label>
            <input
              type="text"
              className="Index-departmentHeader-filter-input"
              name="filter"
              placeholder=""
              onChange={this.onUpdateDepartmentFilter}
            />
          </div>
        </div>

        <TransitionGroup className="Index-departmentList">
          {this.renderDepartmentList()}
        </TransitionGroup>
      </div>
    );
  }
}

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  return {
    departments: state.departments,
  };
};

const mapDispatch = {
  fetchDepartments,
};

const connector = connect(mapStateToProps, mapDispatch);

export default connector(DepartmentList);
