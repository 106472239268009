import { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store";
import { DepartmentAdmin } from "../../apis/dto/DepartmentDetail";
import useDebounce from "../../hooks/useDebounce";
import {
  fetchSearchUserAdmin,
  resetSearchResults,
  addDepartmentAdmin,
  deleteDepartmentAdmin,
  setLoading,
} from "../../features/departments";
import LoadingWheel from "../loadingWheel/LoadingWheel";
import { toastr } from "react-redux-toastr";
import { setIsSaving } from "../../features/globalStateReducer";

type Props = PropsFromRedux & {
  departmentAdmins: DepartmentAdmin[];
  deptCode: string;
};

const DepartmentAdmins = (props: Props) => {
  const [debouncedName, setDebouncedName] = useDebounce(500, "");

  const [queryName, setQueryName] = useState("");

  const {
    departmentAdmins,
    fetchSearchUserAdmin,
    deptCode,
    loading,
    error,
    userAdminsSearchResults,
    resetSearchResults,
    addDepartmentAdmin,
    deleteDepartmentAdmin,
    setLoading,
  } = props;

  // Second Argument Not provided: Runs after EVERY rendering
  // An empty array []: the side-effect runs once after the initial rendering
  // Has props or state values [prop1, prop2, ..., state1, state2]: the side-effect runs only when any depenendecy value changes.
  useEffect(() => {
    if (error) {
      toastr.error(error.title, error.message);
    }
    if (
      (queryName && queryName.length > 1 && !error) ||
      (queryName && queryName.length > 1 && error && error.data !== queryName)
    ) {
      fetchSearchUserAdmin({ searchTerm: queryName, deptCode: deptCode });
    } else {
      resetSearchResults(undefined);
    }

    return function cleanup() {
      // Side-effect cleanup...
    };
  }, [debouncedName, error]);

  const handleSearch = (e: any) => {
    setLoading("idle");
    setQueryName(e.target.value);
    setDebouncedName(e.target.value);
  };

  const addAdmin = async (ucmNetId: string) => {
    await addDepartmentAdmin({ ucmNetId: ucmNetId, deptCode: deptCode });
    setQueryName("");
  };

  const removeAdmin = (ucmNetId: string) => {
    deleteDepartmentAdmin({ ucmNetId: ucmNetId, deptCode: deptCode });
  };

  const admins = () =>
    departmentAdmins.map((admin) => {
      return (
        <div
          key={admin.ucmnetid}
          className="DepartmentPreferences-adminTable-row"
        >
          <div className="DepartmentPreferences-adminTable-row-name">
            {admin.lastName}, {admin.firstName}
          </div>
          <div className="DepartmentPreferences-adminTable-row-netID">
            {admin.ucmnetid}
          </div>
          <a href="#" onClick={() => removeAdmin(admin.ucmnetid)}>
            Remove
          </a>
        </div>
      );
    });

  const searchResults = () =>
    userAdminsSearchResults.map((userAdmin) => {
      return (
        <div
          key={userAdmin.ucmnetid}
          className="DepartmentPreferences-adminTable"
          ng-repeat="item in $ctrl.searchAdminsResults"
        >
          <div
            className="DepartmentPreferences-adminTable-row"
            ng-if="!$ctrl.isAdminSearch && $ctrl.searchAdminsResults && $ctrl.searchAdminsResults.length > 0"
          >
            <div className="DepartmentPreferences-adminTable-row-name">
              {userAdmin.lastName}, {userAdmin.firstName}
            </div>
            <div className="DepartmentPreferences-adminTable-row-netID">
              {userAdmin.ucmnetid}
            </div>
            <a href="#" onClick={() => addAdmin(userAdmin.ucmnetid)}>
              Add
            </a>
          </div>
        </div>
      );
    });

  return (
    <div
      className="AdminPreferencesLayout-adminInfo"
      ng-if="!$ctrl.isSearching"
    >
      <h2>Department Admins</h2>
      <div className="DepartmentPreferences-adminTable">
        {departmentAdmins && departmentAdmins.length > 0 && admins()}
        {departmentAdmins && departmentAdmins.length < 1 && (
          <div
            className="DepartmentPreferences-adminTable-row"
            style={{ gridTemplateColumns: "auto", textAlign: "center" }}
          >
            <div className="DepartmentPreferences-adminTable-row-name">
              No Records Found!
            </div>
          </div>
        )}
      </div>
      <div className="InputLabelPair">
        <label className="InputLabelPair-label" htmlFor="add-admin">
          Add Admin
        </label>
        <input
          type="text"
          id="add-admin"
          placeholder="UCMNetId"
          value={queryName}
          onChange={handleSearch}
          className="InputLabelPair-textInput"
        />
      </div>
      {searchResults()}
      <LoadingWheel
        numberOfDivs={4}
        showLoading={loading === "pending"}
        spinnerOnly={false}
      />
      {userAdminsSearchResults &&
        userAdminsSearchResults.length < 1 &&
        queryName.length > 1 &&
        loading === "succeeded" && (
          <div
            className="DepartmentPreferences-adminTable-row"
            style={{ gridTemplateColumns: "auto", textAlign: "center" }}
          >
            <div className="DepartmentPreferences-adminTable-row-name">
              No Records for '{queryName}'
            </div>
          </div>
        )}
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  const { loading, error, entities } = state.departments.searchUserAdmins;
  return {
    userAdminsSearchResults: entities,
    loading,
    error,
  };
};

const mapDispatch = {
  fetchSearchUserAdmin,
  resetSearchResults,
  addDepartmentAdmin,
  deleteDepartmentAdmin,
  setLoading,
  setIsSaving,
};

const connector = connect(mapStateToProps, mapDispatch);

export default connector(DepartmentAdmins);
