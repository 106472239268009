import close1xD from "../../Content/images/static/7454f71bff45cbe73a7d7768794c4acf/36f65/close.png";
import close1p5x from "../../Content/images/static/7454f71bff45cbe73a7d7768794c4acf/19973/close.png";
import close2x from "../../Content/images/static/7454f71bff45cbe73a7d7768794c4acf/2ca76/close.png";
import expand1xD from "../../Content/images/static/2e7cae20b1f39271e10918e82828f817/36f65/expand.png";
import expand1p5x from "../../Content/images/static/2e7cae20b1f39271e10918e82828f817/19973/expand.png";
import expand2x from "../../Content/images/static/2e7cae20b1f39271e10918e82828f817/2ca76/expand.png";
import link1xD from "../../Content/images/static/f3303da7e43a29a4150a3ef349cc65b1/36f65/link.png";
import link1p5x from "../../Content/images/static/f3303da7e43a29a4150a3ef349cc65b1/19973/link.png";
import link2x from "../../Content/images/static/f3303da7e43a29a4150a3ef349cc65b1/2ca76/link.png";
import download1xD from "../../Content/images/static/97e216490c1942728bc0319f5254ac4f/36f65/download.png";
import download1p5x from "../../Content/images/static/97e216490c1942728bc0319f5254ac4f/19973/download.png";
import download2x from "../../Content/images/static/97e216490c1942728bc0319f5254ac4f/2ca76/download.png";
import personAvatar from "../../Content/images/defaultImage.png";

import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import { People, PeopleDepartment } from "../../../apis/dto/People";
import {
  fetchPhoto,
  setPersonIsLoadImages,
} from "../../../features/peopleSearch";
import vCardFactory from "vcards-js";
import FileSaver from "file-saver";
import LoadingWheel from "../../loadingWheel/LoadingWheel";
import { Link } from "react-router-dom";
import { DepartmentLabels, DeptTitle } from "./subcomponents/DepartmentLabels";

type Props = PropsFromRedux & {
  person: People;
  permaLinkSingleResult: boolean;
};

const PeopleCard = (props: Props) => {
  const { person, permaLinkSingleResult, setPersonIsLoadImages } = props;
  const [expanded, setExpanded] = useState<boolean>(false);
  const vcardTemplate = vCardFactory();
  const [vcardPerson] = useState<typeof vcardTemplate>();

  // Second Argument Not provided: Runs after EVERY rendering
  // An empty array []: the side-effect runs once after the initial rendering
  // Has props or state values [prop1, prop2, ..., state1, state2]: the side-effect runs only when any depenendecy value changes.
  useEffect(() => {
    if (permaLinkSingleResult) {
      setExpanded(permaLinkSingleResult);
    }
    // Side-effect...
    if (
      person.displayPhoto &&
      !person.photo &&
      person.isLoadingImages === "idle"
    ) {
      setPersonIsLoadImages({ person: person, isLoadingImages: "pending" });
      props.fetchPhoto(person);
    }

    return function cleanup() {
      // Side-effect cleanup...
      window.URL.revokeObjectURL(person.photo);
    };
  }, [person]);

  const downloadVCard = (downloadAsName: string, vCardString: string) => {
    const blob = new Blob([vCardString], { type: "text/plain;charset=utf-8" });
    FileSaver.saveAs(blob, downloadAsName + ".vcf", true);
  };

  const generateVCard = async (person: People) => {
    const {
      photo,
      displayPhoto,
      firstName,
      lastName,
      workPhone,
      cellPhone,
      faxPhone,
      email,
    } = person;
    const workingTitle =
      person.departments && person.departments.length > 0
        ? person.departments[0].workingTitle
        : "";
    const location =
      person.departments && person.departments.length > 0
        ? person.departments[0].location
        : "";

    if (vcardPerson) {
      const downloadAsName = lastName + "_" + firstName;
      downloadVCard(downloadAsName, vcardPerson.getFormattedString());
    } else {
      const vcard = vCardFactory();
      vcard.firstName = firstName;
      vcard.lastName = lastName;
      vcard.lastName = lastName;
      vcard.workPhone = workPhone;
      vcard.cellPhone = cellPhone;
      vcard.workFax = faxPhone;
      vcard.workEmail = email;
      vcard.title = workingTitle;
      vcard.organization = "University of California, Merced";
      vcard.workAddress.label = location
        ? location + ", 5200 North Lake Road, Merced, CA, 95343"
        : "5200 North Lake Road, Merced, CA, 95343";
      vcard.workAddress.street = "5200 North Lake Road";
      vcard.workAddress.city = "Merced";
      vcard.workAddress.stateProvince = "CA";
      vcard.workAddress.postalCode = "95343";
      vcard.workAddress.countryRegion = "United States of America";
      vcard.workUrl = "https://www.ucmerced.edu";
      if (displayPhoto) {
        let blob = await fetch(photo).then((r) => r.blob());
        // let reader = new FileReader();
        // await reader.readAsDataURL(blob);
        const photoData: string = await readPhotoBlob(blob);
        vcard.photo.embedFromString(
          photoData
            .replace("data:image/png;base64,", "")
            .replace("data:image/jpeg;base64,", ""),
          "image/png"
        );
      }
      const downloadAsName = lastName + "_" + firstName;
      downloadVCard(downloadAsName, vcard.getFormattedString());
    }
  };

  const readPhotoBlob = async (blob: Blob): Promise<string> => {
    let promise = new Promise((resolve) => {
      let reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
    let result = await promise;
    return result as string;
  };

  const renderToggleIcon = () => {
    if (expanded) {
      return (
        <img
          src={close1xD}
          srcSet={`${close1xD} 1x, ${close1p5x} 1.5x, ${close2x} 2x`}
          alt="Close Icon"
        />
      );
    } else {
      return (
        <img
          src={expand1xD}
          srcSet={`${expand1xD} 1x, ${expand1p5x} 1.5x, ${expand2x} 2x`}
          alt="Expand Icon"
        />
      );
    }
  };

  const avatarInitials = () => {
    if (person.displayPhoto) return null;
    return (
      <div className="ResultBlock-avatar-initials">
        <div className="ResultBlock-avatar-initials-initials">
          {person.firstName && person.firstName[0]}
          {person.lastName && person.lastName[0]}
        </div>
      </div>
    );
  };

  const picloading = () => {
    if (
      person.displayPhoto &&
      (person.isLoadingImages === "pending" ||
        person.isLoadingImages === "idle" ||
        person.isLoadingImages === "failed")
    ) {
      return (
        <div>
          <div
            className="ResultBlock-avatar-blur "
            style={{ backgroundImage: `url(${personAvatar})` }}
          />
          <div
            className="ResultBlock-avatar-image "
            style={{ backgroundImage: `url(${personAvatar})` }}
          />
          <LoadingWheel
            showLoading={person.isLoadingImages === "pending"}
            spinnerOnly={true}
            numberOfDivs={4}
          />
        </div>
      );
    } else {
      return null;
    }
  };
  const picLoaded = () => {
    if (person.displayPhoto && person.isLoadingImages === "succeeded") {
      return (
        <div>
          <div
            className="ResultBlock-avatar-blur "
            style={{ background: `url(${person.photo})` }}
          />
          <div
            className="ResultBlock-avatar-image "
            style={{ backgroundImage: `url(${person.photo})` }}
          />
        </div>
      );
    }
    return null;
  };

  const personAffiliation = () => {
    if (person.departments.length) return null;
    return (
      <span className="workingTitle ResultBlock-info-office">
        {person.affiliation}
      </span>
    );
  };

  const departmentsNotPrimary = person.departments
    .filter((department) => !department.primary)
    .map((peopleDepartment, index) => {
      return (
        <span key={peopleDepartment.departmentCode}>
          {(index ? "<span>, </span>" : "") + peopleDepartment.departmentTitle}
        </span>
      );
    });

  const majors = [person.major1, person.major2].filter(Boolean).join(", ");
  const minors = [
    person.major1minor1,
    person.major1minor2,
    person.major2minor1,
    person.major2minor2,
  ]
    .filter(Boolean)
    .join(", ");

  const majorsJSX = () => {
    if (
      !(
        person.departments === undefined ||
        person.departments === null ||
        person.departments.length === 0
      ) &&
      majors
    ) {
      return <div className="accClosedDept">{{ majors }}</div>;
    }
  };

  const peopleDepartmentLocation = (
    showLocation: boolean,
    peopleDepartment: PeopleDepartment
  ) => {
    if (showLocation) {
      return (
        <div className="TableRow-assignment">
          <div className="TableRow-label">Office</div>
          <div className="TableRow-value">{peopleDepartment.location}</div>
        </div>
      );
    }
  };
  const personDepartmentJsx = person.departments.map((peopleDepartment) => {
    return (
      <div key={peopleDepartment.departmentCode} className="tag-parent">
        <div
          className={`tag ${peopleDepartment.primary ? "tag-primary" : ""}`}
        />
        <div>
          {peopleDepartment.workingTitle && (
            <DeptTitle
              label={`${peopleDepartment.workingTitle}, `}
              isPrimary={peopleDepartment.primary}
              italic={true}
            />
          )}
          <a
            title={peopleDepartment.departmentTitle}
            className={`pointer nohrefstyle ${
              peopleDepartment.primary ? "ResultBlock-info-office" : ""
            }`}
            href={`/department/${peopleDepartment.departmentCode}`}
          >
            {peopleDepartment.departmentTitle}
          </a>
          {peopleDepartmentLocation(!person.student, peopleDepartment)}
        </div>
      </div>
    );
  });
  const assistants = person.assistants.map((assistant) => {
    return (
      <div key={assistant.id} className="ResultBlock-assistant">
        <div className="ResultBlock-assistant-title">
          {assistant.assistantType} Assistant
        </div>
        <div className="ResultBlock-assistant-info">
          <div>
            <div className="ResultBlock-assistant-info-name">
              {assistant.name}
            </div>
            <div className="ResultBlock-assistant-info-location">
              {assistant.location}
            </div>
          </div>
          <div className="ResultBlock-assistant-info-table">
            <div className="TableRow">
              <div className="TableRow-label">Email</div>
              <div className="TableRow-value">
                <a href={`mailto:${assistant.email}`}>{assistant.email}</a>
              </div>
            </div>
            {assistant.phone && (
              <div className="TableRow">
                <div className="TableRow-label">Phone</div>
                <div className="TableRow-value">
                  <a href={`tel:${assistant.phone}`}>{assistant.phone}</a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  });

  return (
    <React.Fragment>
      {(person.currentUserIsOwner || person.currentUserIsAdmin) && (
        <div className="ResultBlock-editButton">
          {person.currentUserIsAdmin && !person.student && (
            <Link
              to={`/adminPreferences/${person.ucmnetid}`}
              className="EditButton EditButton--tab"
            >
              Edit Info
            </Link>
          )}
          {!person.currentUserIsAdmin &&
            person.currentUserIsOwner &&
            !person.student && (
              <Link
                to={`/myPreferences`}
                className="EditButton EditButton--tab"
              >
                Edit Info
              </Link>
            )}
          {((person.currentUserIsOwner && person.student) ||
            (person.currentUserIsAdmin && person.student)) && (
            <Link
              to={`/studentPreferences/${person.ucmnetid}`}
              className="EditButton EditButton--tab"
            >
              Edit Info
            </Link>
          )}
        </div>
      )}
      <section
        className={`ResultBlock ${expanded ? "ResultBlock--expanded" : ""}`}
      >
        <div className="ResultBlock-avatar">
          {avatarInitials()}
          {picloading()}
          {picLoaded()}
        </div>

        <div className="ResultBlock-info">
          <div
            onClick={() => {
              if (!permaLinkSingleResult) {
                setExpanded(!expanded);
              }
            }}
            className="ResultBlock-info-persistent pointer"
          >
            <div className="ResultBlock-info-persistent-nameDescription">
              <div className="ResultBlock-info-name">
                <a className="pointer nohrefstyle">
                  {" "}
                  {person.lastName}{person.firstName && (", " + person.firstName)}
                </a>
              </div>
              <div className="ResultBlock-info-descriptor">
                {personAffiliation()}
                <DepartmentLabels departments={person.departments} />
                <div className="deptTitle">{departmentsNotPrimary}</div>
                {majorsJSX()}
              </div>
            </div>
            <div className="ResultBlock-controls">
              {!permaLinkSingleResult && renderToggleIcon()}
            </div>
          </div>

          <div className="ResultBlock-info-optional">
            <div className="ResultBlock-info-general">
              {personDepartmentJsx}
              <div className="ResultBlock-contactTable">
                <div className="TableRow">
                  <div className="TableRow-label">Email</div>
                  <div className="TableRow-value">
                    <a href={`mailto:${person.email}`}>{person.email}</a>
                  </div>
                </div>
                {person.workPhone && (
                  <div className="TableRow">
                    <div className="TableRow-label">Phone</div>
                    <div className="TableRow-value">
                      <a href={`tel:${person.workPhone}`}>{person.workPhone}</a>
                    </div>
                  </div>
                )}
                {person.cellPhone && (
                  <div className="TableRow">
                    <div className="TableRow-label">Mobile</div>
                    <div className="TableRow-value">
                      <a href={`tel:${person.cellPhone}`}>{person.cellPhone}</a>
                    </div>
                  </div>
                )}
                {person.faxPhone && (
                  <React.Fragment>
                    <div className="TableRow">
                      <div className="TableRow-label">Fax</div>
                      <div className="TableRow-value">
                        <a href={`tel:${person.faxPhone}`}>{person.faxPhone}</a>
                      </div>
                    </div>
                  </React.Fragment>
                )}
                {person.student && (
                  <React.Fragment>
                    <div className="TableRow">
                      <div className="TableRow-label">Major(s)</div>
                      <div className="TableRow-value">
                        <div className="TableRow-value">{majors}</div>
                      </div>
                    </div>
                    <div className="TableRow">
                      <div className="TableRow-label">Minor(s)</div>
                      <div className="TableRow-value">
                        <div className="TableRow-value">{minors}</div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
              {assistants}
            </div>

            {permaLinkSingleResult && (
              <a
                onClick={() => generateVCard(person)}
                className="ResultBlock-info-actions-vCard"
              >
                <div>Download vCard</div>
                <img
                  src={download1xD}
                  srcSet={`${download1xD} 1x, ${download1p5x} 1.5x, ${download2x} 2x`}
                  alt="Download"
                />
              </a>
            )}
            {!permaLinkSingleResult && (
              <div className="ResultBlock-info-actions">
                <a
                  target="_blank"
                  href={`/person/${person.ucmnetid}`}
                  className="ResultBlock-info-actions-permalink"
                >
                  <div>Contact Permalink</div>
                  <img
                    src={link1xD}
                    srcSet={`${link1xD} 1x, ${link1p5x} 1.5x, ${link2x} 2x`}
                    alt="Permalink"
                  />
                </a>
                <a
                  className="ResultBlock-info-actions-vCard"
                  onClick={() => generateVCard(person)}
                >
                  <div>Download vCard</div>
                  <img
                    src={download1xD}
                    srcSet={`${download1xD} 1x, ${download1p5x} 1.5x, ${download2x} 2x`}
                    alt="Download"
                  />
                </a>
              </div>
            )}
          </div>
          {expanded && <div id="cssHack" className="hack_vertical_height" />}
        </div>
      </section>
    </React.Fragment>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapDispatch = { fetchPhoto, setPersonIsLoadImages };

const connector = connect(undefined, mapDispatch);

export default connector(PeopleCard);
