import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../store";
import ucmercedmascot125w from "../../Content/images/static/7a9925e9fbe9dd6f6a1dc302c0b07289/62d80/uc-merced-mascot.png";
import ucmercedmascot250w from "../../Content/images/static/7a9925e9fbe9dd6f6a1dc302c0b07289/e1953/uc-merced-mascot.png";
import ucmercedmascot500wD from "../../Content/images/static/7a9925e9fbe9dd6f6a1dc302c0b07289/46604/uc-merced-mascot.png";
import ucmercedmascot750w from "../../Content/images/static/7a9925e9fbe9dd6f6a1dc302c0b07289/d8815/uc-merced-mascot.png";
import ucmercedmascot1000w from "../../Content/images/static/7a9925e9fbe9dd6f6a1dc302c0b07289/31987/uc-merced-mascot.png";
import ucmercedmascot1200w from "../../Content/images/static/7a9925e9fbe9dd6f6a1dc302c0b07289/f3583/uc-merced-mascot.png";
import { Link } from "react-router-dom";

type Props = PropsFromRedux & {};

const NotFound = (props: Props) => {
  return (
    <div className="PageWrapper">
      <div className="PageNotFound">
        <div>
          <img
            className="PageNotFound-image"
            src={ucmercedmascot500wD}
            srcSet={`
							${ucmercedmascot125w} 125w, 
							${ucmercedmascot250w} 250w, 
							${ucmercedmascot500wD} 500w, 
							${ucmercedmascot750w} 750w,
							${ucmercedmascot1000w} 1000w,
							${ucmercedmascot1200w} 1200w,`}
            sizes="(max-width: 500px) 100vw, 500px"
            alt="UC Merced Seal"
          />
        </div>
        <div>
          <h1>Page Not Found</h1>
          <p>
            <Link to="/">Go Home</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  return {};
};

const mapDispatch = {};

const connector = connect(mapStateToProps, mapDispatch);

export default connector(NotFound);
