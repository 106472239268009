import React, { useEffect, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../store";
import DepartmentHeader from "../../departmentheader/DepartmentHeader";
import PeopleSearchResults from "../../peopleSearchResults/PeopleSearchResults";
import SearchBar from "../../searchBar/searchBar";
import {
  setIsDirty,
  setShowDepartmentList,
} from "../../../features/peopleSearch";

type Props = PropsFromRedux & {};

const DepartmentPage = (props: Props) => {
  const searchBarRef: React.MutableRefObject<any | null> = useRef(null);

  const { setShowDepartmentList, setIsDirty } = props;

  const handleSearchBarSubmit = () => {
    console.log("searching");
    setShowDepartmentList(false);
  };
  const onClearSearchBarHandle = () => {
    searchBarRef.current();
    console.log("text Cleared");
  };

  // Second Argument Not provided: Runs after EVERY rendering
  // An empty array []: the side-effect runs once after the initial rendering
  // Has props or state values [prop1, prop2, ..., state1, state2]: the side-effect runs only when any depenendecy value changes.
  useEffect(() => {
    setIsDirty(true);

    return function cleanup() {
      // Side-effect cleanup...
    };
  }, []);

  return (
    <div className="PageWrapper">
      <div className="Department">
        <SearchBar ref={searchBarRef} onSearchSubmit={handleSearchBarSubmit} />

        <DepartmentHeader />

        <PeopleSearchResults clearSearchBarText={onClearSearchBarHandle} />
      </div>
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  return {};
};

const mapDispatch = {
  setShowDepartmentList,
  setIsDirty,
};

const connector = connect(mapStateToProps, mapDispatch);

export default connector(DepartmentPage);
