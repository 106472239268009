import { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { RootState } from "../../store";
import LoadingWheel from "../loadingWheel/LoadingWheel";
import NonEditableStudentInfo from "../nonEditableStudentInfo/NonEditableStudentInfo";
import arrayMutators from "final-form-arrays";
import personAvatar from "../Content/images/defaultImage.png";
import { Form, Field } from "react-final-form";
import {
  fetchStudentPreferences,
  fetchPhoto,
  updateStudentPreferences,
} from "../../features/studentPersonalInfo";
import { StudentDetailsExtended } from "../../apis/dto/StudentDetails";
import { toastr } from "react-redux-toastr";
import { setIsSaving } from "../../features/globalStateReducer";

import "./StudentInfo.scss";

type Props = PropsFromRedux & {};

const StudentInfo = (props: Props) => {
  const { ucmnetid } = useParams();

  const {
    fetchStudentPreferences,
    fetchPhoto,
    updateStudentPreferences,
    error,
    loading,
    selectStudentPreferences,
    setIsSaving,
  } = props;

  const onSubmit = async (values: any) => {
    const dataToSave: StudentDetailsExtended = values;
    updateStudentPreferences(dataToSave).then((_) => {
      setIsSaving(true);
      setTimeout(() => setIsSaving(false), 3000);
    });
  };

  // Second Argument Not provided: Runs after EVERY rendering
  // An empty array []: the side-effect runs once after the initial rendering
  // Has props or state values [prop1, prop2, ..., state1, state2]: the side-effect runs only when any depenendecy value changes.
  useEffect(() => {
    if (error) {
      toastr.error(error.title, error.message);
    }

    if (
      selectStudentPreferences &&
      selectStudentPreferences.photoLoading &&
      selectStudentPreferences.photoLoading.error
    ) {
      const { title, message } = selectStudentPreferences.photoLoading.error;
      toastr.error(title, message);
    }

    if (!selectStudentPreferences && loading === "idle" && ucmnetid) {
      fetchStudentPreferences(ucmnetid);
      //await fetchUserPreferences(ucmnetid);
    }
    if (
      selectStudentPreferences &&
      !selectStudentPreferences.photo &&
      selectStudentPreferences.photoLoading.loading === "idle"
    ) {
      fetchPhoto(selectStudentPreferences);
    }

    return function cleanup() {
      // Side-effect cleanup...
    };
  }, [selectStudentPreferences, error]);
  return (
    <div className="AdminPreferencesLayout-personalInfo">
      <Link to="/">← Back to Department Page</Link>
      {loading === "succeeded" && selectStudentPreferences && (
        <div>
          <h1 className="PreferencesAdmin-heading">Personal Information</h1>
          <Link target="_blank" to={`/person/${ucmnetid}`}>
            Contact Permalink
          </Link>
          <NonEditableStudentInfo
            studentPreferences={
              selectStudentPreferences as StudentDetailsExtended
            }
          />

          <div className="livedName-box">
            <div
              className={`livedName-split ${
                selectStudentPreferences.livedName ? "livedName-split-3" : ""
              }`}
            >
              {selectStudentPreferences.livedName && (
                <div className="TableRow-label livedName-text">Lived Name</div>
              )}
              {selectStudentPreferences.livedName && (
                <div className="TableRow-value livedName-text">
                  {selectStudentPreferences.livedName}
                </div>
              )}
              {!selectStudentPreferences.livedName && (
                <div ng-if="!$ctrl.user.livedName" className="livedName-Label">
                  You can now set a lived name for the directory
                </div>
              )}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={selectStudentPreferences.livedNameUrl}
                  className="Button--link Button Button--primary livedName-button"
                >
                  Edit in {selectStudentPreferences.livedNameLinkText}
                </a>
            </div>
          </div>
          {selectStudentPreferences.privateAccount && (
            <div className="privateAccount-box">
              <div>
                Due to your student confidentiality settings, this account is
                not displayed in the directory.
              </div>
            </div>
          )}
          <Form
            onSubmit={onSubmit}
            initialValues={selectStudentPreferences}
            //subscribe to form state and to determine these see that they are use below e.g.
            // {({ handleSubmit, form, submitting, pristine, values }) => {
            // this should be either same or a subset of the below
            subscription={{ submitting: true, pristine: true, values: true }}
            mutators={{
              ...arrayMutators,
            }}
            validate={(formValues) => {
              return {};
            }}
          >
            {({ handleSubmit, form, submitting, pristine }) => {
              return (
                <form name="studentPreferences" onSubmit={handleSubmit}>
                  <div className="PhotoPreferences">
                    {selectStudentPreferences.photoLoading.loading !==
                      "succeeded" && (
                      <div
                        className="PhotoPreferences-image"
                        style={{
                          backgroundImage: `url(${personAvatar})`,
                        }}
                      >
                        <LoadingWheel
                          numberOfDivs={4}
                          spinnerOnly={true}
                          showLoading={
                            selectStudentPreferences.photoLoading.loading ===
                            "pending"
                          }
                        />
                      </div>
                    )}
                    {selectStudentPreferences.photoLoading.loading ===
                      "succeeded" && (
                      <Field name="photo">
                        {({ input: { name, value } }) => {
                          const photoUrl = value || personAvatar;
                          return (
                            <div
                              className="PhotoPreferences-image"
                              style={{ backgroundImage: `url(${photoUrl})` }}
                            ></div>
                          );
                        }}
                      </Field>
                    )}
                    <div className="PhotoPreferences-edit">
                      <div className="PhotoPreferences-edit-heading">
                        <div className="PhotoPreferences-edit-heading-label">
                          Photo
                        </div>
                      </div>
                      <label className="Checkbox">
                        <Field
                          name="displayPhoto"
                          className="Checkbox-input"
                          component="input"
                          type="checkbox"
                        />
                        Display photo in the directory
                      </label>
                    </div>
                  </div>

                  <section>
                    <div className="StudentPreferences-majorMinorOptions">
                      <h3 className="StudentPreferences-majorMinorOptions-label">
                        Course of Study
                      </h3>
                      <label className="Checkbox">
                        <Field
                          name="displayMajors"
                          className="Checkbox-input"
                          component="input"
                          type="checkbox"
                        />
                        Display my Major(s) in the directory
                      </label>
                      <label className="Checkbox">
                        <Field
                          name="displayMinors"
                          className="Checkbox-input"
                          component="input"
                          type="checkbox"
                        />
                        Display my Minor(s) in the directory
                      </label>
                    </div>
                    <div
                      className="InputLabelPair"
                      style={{ maxWidth: "20em" }}
                    >
                      <label
                        className="InputLabelPair-label"
                        htmlFor="phone-number"
                      >
                        Phone Number*
                      </label>
                      <Field
                        name="studentPhone"
                        className="InputLabelPair-textInput"
                        component="input"
                        type="text"
                      />
                    </div>
                    <p className="caption">
                      *Edit will only be reflected in the Directory
                    </p>
                    <div className="FormActions">
                      <button
                        className="Button Button--primary"
                        type="submit"
                        id="studentPreferencesFormSubmit"
                        disabled={submitting}
                      >
                        Save Changes
                      </button>
                      <button
                        type="button"
                        className="Button Button--secondary"
                        onClick={form.reset}
                        disabled={submitting || pristine}
                      >
                        Discard Changes
                      </button>
                    </div>
                  </section>
                </form>
              );
            }}
          </Form>
        </div>
      )}

      <LoadingWheel
        numberOfDivs={4}
        showLoading={loading === "pending"}
        spinnerOnly={false}
      />
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  const { error, loading, entity } = state.studentPersonalInfo;

  return {
    error,
    loading,
    selectStudentPreferences: entity,
  };
};

const mapDispatch = {
  fetchStudentPreferences,
  fetchPhoto,
  updateStudentPreferences,
  setIsSaving,
};

const connector = connect(mapStateToProps, mapDispatch);

export default connector(StudentInfo);
