import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { StudentDetailsExtended } from "../apis/dto/StudentDetails";
import idm from "../apis/idm";
import { ErrorToastr } from "../components/utility/ErrorToastr";

interface ValidationErrors {
  errorMessage: string;
  field_errors: Record<string, string>;
}

export const fetchStudentPreferences = createAsyncThunk<
  StudentDetailsExtended | null | undefined,
  string
>("StudentPreferences", async (ucmnetid: string, thunkApi) => {
  try {
    const response = await idm.get(`/directory/studentDetails/${ucmnetid}`, {
      withCredentials: true,
    });

    if (response.data) {
      response.data.ucmnetid = ucmnetid;
    }
    return response.data as StudentDetailsExtended | null;
  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err; // cast the error for access
    if (!error.response) {
      throw err;
    }

    const meta = { errorMessage: err.message, status: error.response.status };
    const response = { responseMessage: error.response.data, meta: meta };
    return thunkApi.rejectWithValue(response);
  }
});

export const updateStudentPreferences = createAsyncThunk<
  StudentDetailsExtended,
  StudentDetailsExtended
>(
  "updateStudentPreferences",
  async (data: StudentDetailsExtended, thunkApi) => {
    try {
      const response = await idm.post("/directory/studentPreferences", data, {
        withCredentials: true,
      });
      if (response.status !== 204) {
        throw new Error("Error - " + response.data);
      }
      return data as StudentDetailsExtended;
    } catch (err: any) {
      let error: AxiosError<ValidationErrors> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      const meta = { errorMessage: err.message, status: error.response.status };
      const response = { responseMessage: error.response.data, meta: meta };
      return thunkApi.rejectWithValue(response);
    }
  }
);

interface PhotoResponse {
  response: any;
  studentDetailsExtended: StudentDetailsExtended;
}

export const fetchPhoto = createAsyncThunk<
  PhotoResponse,
  StudentDetailsExtended
>(
  "photoFetchStudentPreferences",
  async (student: StudentDetailsExtended, thunkApi) => {
    try {
      let url;
      if (student.photoUrl) {
        const response = await idm.get(student.photoUrl, {
          responseType: "blob",
          withCredentials: true,
        });
        url = window.URL.createObjectURL(response.data);
      }

      const responseData: PhotoResponse = {
        response: url,
        studentDetailsExtended: student,
      };
      return responseData;
    } catch (err: any) {
      let error: AxiosError = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      const meta = { errorMessage: err.message, status: error.response.status };
      const response = { responseMessage: error.response.data, meta: meta };
      return thunkApi.rejectWithValue(response);
    }
  }
);

interface StudentState {
  error: ErrorToastr | null | undefined;
  loading: "idle" | "pending" | "succeeded" | "failed";
  entity: StudentDetailsExtended | undefined;
}

// Then, handle actions in your reducers:
const studentSlice = createSlice({
  name: "student",
  initialState: {
    entity: undefined,
    loading: "idle",
    error: null,
  } as StudentState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder

      .addCase(
        fetchStudentPreferences.pending,
        (state: any, action: PayloadAction<any>) => {
          if (state.loading !== "pending") {
            state.loading = "pending";
          }
        }
      )
      .addCase(
        fetchStudentPreferences.fulfilled,
        (
          state: any,
          action: PayloadAction<StudentDetailsExtended | null | undefined>
        ) => {
          if (action.payload) {
            state.entity = action.payload;
            Object.assign(state.entity, {
              photoLoading: {
                loading: "idle",
                error: null,
              },
              photo: null as any,
            });
          }
          state.loading = "succeeded";
        }
      )
      .addCase(fetchStudentPreferences.rejected, (state, action: any) => {
        if (state.loading === "pending") {
          if (action.payload) {
            const errorObj: ErrorToastr = {
              title: "!Error - " + action.payload.meta.status,
              message:
                action.payload && action.payload.responseMessage.message
                  ? action.payload.responseMessage.message
                  : action.payload.meta.errorMessage,
            };
            state.error = errorObj;
          } else {
            const errorObj: ErrorToastr = {
              title: "!Unknown Error",
              message: "Contact Help Desk - " + action.error.message,
            };
            state.error = errorObj;
          }

          state.loading = "failed";
        }
      })
      .addCase(fetchPhoto.pending, (state, action) => {
        if (state.entity) {
          if (state.entity.photoLoading.loading === "idle") {
            state.entity.photoLoading.loading = "pending";
          }
        }
      })
      .addCase(
        fetchPhoto.fulfilled,
        (state, action: PayloadAction<PhotoResponse>) => {
          if (state.entity) {
            state.entity.photo = action.payload.response;
            state.entity.photoLoading.loading = "succeeded";
          }
        }
      )
      .addCase(fetchPhoto.rejected, (state, action: any) => {
        if (state.entity && state.entity.photoLoading) {
          state.entity.photoLoading.loading = "failed";

          if (action.payload) {
            const errorObj: ErrorToastr = {
              title: "!Error - " + action.payload.meta.status,
              message:
                action.payload && action.payload.responseMessage.message
                  ? action.payload.responseMessage.message
                  : action.payload.meta.errorMessage,
            };
            state.entity.photoLoading.error = errorObj;
          } else {
            const errorObj: ErrorToastr = {
              title: "!Unknown Error",
              message: "Contact Help Desk - " + action.error.message,
            };
            state.entity.photoLoading.error = errorObj;
          }
        }
      })
      .addCase(updateStudentPreferences.pending, (state, action) => {
        if (state.loading !== "pending") {
          state.loading = "pending";
        }
      })
      .addCase(
        updateStudentPreferences.fulfilled,
        (state, action: PayloadAction<StudentDetailsExtended>) => {
          if (action && action.payload) {
            state.entity = action.payload;
          }
          state.loading = "succeeded";
        }
      )
      .addCase(updateStudentPreferences.rejected, (state, action: any) => {
        if (state.loading === "pending") {
          if (action.payload) {
            const errorObj: ErrorToastr = {
              title: "!Error - " + action.payload.meta.status,
              message:
                action.payload && action.payload.responseMessage.message
                  ? action.payload.responseMessage.message
                  : action.payload.meta.errorMessage,
            };
            state.error = errorObj;
          } else {
            const errorObj: ErrorToastr = {
              title: "!Unknown Error",
              message: "Contact Help Desk - " + action.error.message,
            };
            state.error = errorObj;
          }

          state.loading = "failed";
        }
      });
  },
});

export default studentSlice.reducer;
