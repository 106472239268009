import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { RootState } from "../../store";
import { useEffect } from "react";

interface Props {
  component: React.ComponentType;
  path?: string;
  isPrivate?: boolean;
  title?: string;
}

export const PrivateRoute: React.FC<Props> = ({
  component: RouteComponent,
  title,
  isPrivate,
}) => {
  const authState = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  // Second Argument Not provided: Runs after EVERY rendering
  // An empty array []: the side-effect runs once after the initial rendering
  // Has props or state values [prop1, prop2, ..., state1, state2]: the side-effect runs only when any depenendecy value changes.
  useEffect(() => {
    if (title) {
      const AppTitle = "UCM Directory";
      document.title = AppTitle + ": " + title;
    }
    return function cleanup() {
      // Side-effect cleanup...
    };
  });

  if (isPrivate === undefined || !isPrivate) {
    return <RouteComponent />;
  } else if (authState.loggedIn) {
    return <RouteComponent />;
  } else if (!authState.loggedIn && authState.loading === "succeeded") {
    return (
      <Navigate
        to={
          (window.location.href = `${
            process.env.REACT_APP_SIGN_IN_URL as string
          }${location.pathname}`)
        }
      />
    );
  } else {
    return null;
  }
};
