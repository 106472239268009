import { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import LoadingWheel from "../../loadingWheel/LoadingWheel";
import { RootState } from "../../../store";
import { setShowDepartmentList } from "../../../features/peopleSearch";
import PersonSearchResult from "../../peopleSearchResults/PersonSearchResult";

type Props = PropsFromRedux & {};

const Person = (props: Props) => {
  const {
    loading,
  } = props;

  // Second Argument Not provided: Runs after EVERY rendering
  // An empty array []: the side-effect runs once after the initial rendering
  // Has props or state values [prop1, prop2, ..., state1, state2]: the side-effect runs only when any depenendecy value changes.
  useEffect(() => {
    // Side-effect...

    return function cleanup() {
      // Side-effect cleanup...
    };
  });

  return (
    <div className="PageWrapper">
      <div className="Results">
        <LoadingWheel
          showLoading={loading === "pending"}
          spinnerOnly={false}
          numberOfDivs={4}
        />
      </div>

      <PersonSearchResult />
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  return {
    loading: state.people.loading,
    showDepartemntList: state.people.showDepartemntList,
  };
};

const mapDispatch = {
  setShowDepartmentList,
};

const connector = connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
});

export default connector(Person);
