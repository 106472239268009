import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store";
import CollapsePanel from "./CollapsePanel";
import { Link } from "react-router-dom";
type Props = PropsFromRedux & {};

const FrequentlyAskQuestions = (props: Props) => {
  const faqList = [
    {
      question: "What did the directory upgrade project consist of? ",
      answer:
        "<p>Version 2.5: The latest version of the directory includes a new self-service feature that enables faculty, staff, and student's ability to update information about their directory contact cards. Contact card information includes phone number, location, photo, zoom, and major/minor. This version also allows directory administrators to update individual contact card information on behalf of faculty and staff. It also enables admins to update departmental information like department working name, department description, department contact information, and other helpful features to assist in keeping the directory information up to date. </p>" +
        "<p>Version 2.0: This version builds on an entirely new user interface design, user experience and optimized for multiple device types, Desktop, Tablet, and Mobile, to help you find faculty, staff, and students across the organization. The search experience has also been enhanced with a new algorithm that supports keyword match across name, title, department, and optimized for relevance. </p>",

      defaultSortOrder: 1,
      accordianOpen: false,
    },
    {
      question: "What fields can I search by?  ",
      answer:
        "The directory supports search by First Name, Last Name, Department, UCM Net ID, and Phone Number. ",
      defaultSortOrder: 2,
    },
    {
      question: "How do I display my photo? ",
      answer:
        'Sign in to the directory -> click on the "Edit Info" Icon next to your name -> Click on the check box that says "Display photo in the directory." -> Save Changes at the bottom of the page.',
      defaultSortOrder: 3,
    },
    {
      question: "Can I use my own photo as my directory photo? ",
      answer:
        '<p>Yes, this is one of the newest features of the directory. We have provided some photo guidelines you have to adhere to and can be found above the photo utility. They are labeled "PHOTO Image Guidelines."</p>' +
        '<p>To update, Sign in to the directory -> click on the "Edit Info" Icon next to your name -> Click Choose File and select the photo you would like to use. ->Check the box "Display photo in the directory." and click "Save Changes."</p>' +
        "<p>*Note we process each image through an algorithm to ensure it meets some basic guidelines.</p>",
      defaultSortOrder: 4,
    },
    {
      question: "What if my personal information shows incorrectly?  ",
      answer:
        'For Faculty and Staff, sign in to the directory and click "edit info" to update the respective areas. If the information you are trying to correct is not available, contact a directory admin under the section "NON EDITABLE INFORMATION." -> "To correct the non-editable information above, contact ..."',
      defaultSortOrder: 5,
    },
    {
      question: "How do I add or update my personal directory information? ",
      answer:
        '<p>For Faculty and Staff, sign in to the directory and click "edit info" to update the respective areas. If the information you are trying to correct is not available, contact a directory admin under the section "NON EDITABLE INFORMATION." -> "To correct the non-editable information above, contact ..."</p>' +
        "<p>If you need further assistance, please contact your Chief Administrative Officer (CAO).</p>",

      defaultSortOrder: 6,
    },
    {
      question: "What if I am listed under the wrong department? ",
      answer:
        "Contact your Chief Administrative Officer (CAO). The department to person association is derived from UCPath.",
      defaultSortOrder: 7,
    },
    {
      question:
        "What if I'm having technical issues or the directory is not rendering correctly for me?  ",
      answer:
        'Contact the  <a href="https://it.ucmerced.edu/service-desk">OIT Service Desk</a>. ',
      defaultSortOrder: 8,
    },
    {
      question:
        "I'm a CAO, what tools do I have to update my department information?  ",
      answer:
        'As a CAO, you have access to update departmental information like department working title, department phone, department description, and department location. Sign in to the directory and click "edit info" -> on the "Department Information" box to the right-hand side -> Click the department you would like to update. If you\'re the CAO of a department and do not see the department listed under "Department Information" please contact the OIT Service Desk and Open a ticket to request access.',
      defaultSortOrder: 9,
    },
    {
      question: "I'm a CAO, what information can I manage in the directory?   ",
      answer:
        "<p>As a CAO, you can manage departmental and individual Directory information, including general departmental information like Department Working Title, Department Phone, Fax, Department Email, Department Website, Department Description, Manage Administrative Access, Assigning an Executive and Administrative Assistant. You can also manage individual department member information like Working Title, Office Phone, Mobile Phone, Zoom Personal Link, Building, and Room.</p>" +
        '<p>Sign in to the directory and click "edit info" -> on the "Department Information" box to the right-hand side -> Click the department you would like to update.</p>' +
        '<p>If you need further assistance, please contact contact the <a href="https://it.ucmerced.edu/service-desk">OIT Service Desk</a>.</p>',
      defaultSortOrder: 10,
    },
    {
      question:
        "I'm a CAO, what is the process to request access to manage a department and member information?",
      answer:
        '<p>CAO\'s were given administrative access to their respective department by the implementation team. In the self-service directory portal, you can manage access by signing in to the directory and clicking "edit info" -> on the "Department Information" box to the right-hand side -> Click the department -> Under "Department Admins." </p>' +
        '<p>To Add administrative access, search for the person\'s name under "Add Admin." Once found, click "Add."</p>' +
        "<p>*An email will be sent to the person, notifying them of the newly provided access.</p>" +
        '<p>To Remove administrative access, click on "Remove." </p>' +
        '<p>If you need further assistance, please contact contact the <a href="https://it.ucmerced.edu/service-desk">OIT Service Desk</a>.</p>',
      defaultSortOrder: 11,
    },
    {
      question:
        "I'm an Executive Assistant (EA) and would like to know how best to support my executive team?  ",
      answer:
        "As an EA, you can ensure that the executive team you support is listed in the correct department, has an accurate working title, phone, email address, building, and room. If the information is incorrect, you can work with your CAO to have it updated.",
      defaultSortOrder: 12,
    },
    {
      question:
        "I'm an Executive Assistant and would like to know how to be listed in the directory as part of the executive members I support?  ",
      answer:
        '<p>Directory Administrators have access to update a contact record with an EA or AA. If you do not have administrative access, you will need to access your department admin to update your contact record. You can find who the department admin is by -> Signing into the directory and click "edit info" -> Under "NON EDITABLE INFORMATION." At the bottom, you will find your point of contact "To correct the non-editable information above, please contact." </p>' +
        '<p>If you have administrative access -> Sign into the directory and click "edit info." "Department Information" box to the right-hand side -> Click the department -> On the top click -> View Department Page -> locate person -> Click "Edit Info." Scroll to the bottom to the "ADD ASSISTANT." section. Search and add the EA or AA. -> Click "Save Changes."</p>' +
        '<p>If you need further assistance, please contact contact the <a href="https://it.ucmerced.edu/service-desk">OIT Service Desk</a>.</p>',
      image: "../Content/images/sample.png",
      defaultSortOrder: 13,
    },
    {
      question:
        "I just joined UC Merced, how long will it take until I show up in the directory? ",
      answer:
        "You can expect to see yourself in the directory as early 24 – 72 hours from your start date.",
      defaultSortOrder: 14,
    },
    {
      question:
        "What is the best way to provide feedback on the new directory and request future enhancements?   ",
      answer:
        'You can provide feedback to the directory product at <a href="https://abadwal.typeform.com/to/rJEZ5xrv">FeedBack</a> ',
    },
  ];

  const renderList = () =>
    faqList.map((faq, index) => {
      return (
        <React.Fragment>
          <div style={{ height: "10px" }}> </div>
          <CollapsePanel
            key={faq.question}
            answer={faq.answer}
            question={faq.question}
            image={faq.image}
            id={"key_" + index}
          />
          <div style={{ height: "15px" }}> </div>
        </React.Fragment>
      );
    });
  return (
    <React.Fragment>
      <div className="backtoHome hideOnDesktop">
        <Link to="/">← All Departments</Link>
        <br />
      </div>
      <br className="hideOnDesktop" />
      <br className="hideOnDesktop" />
      <div className="faqHeader">
        <div className="backtoHome hideOnMobile">
          <Link to="/">← All Departments</Link>
        </div>
        <div className="faqHeader-heading" style={{ textAlign: "center" }}>
          Frequently Asked Questions
        </div>
      </div>

      {renderList()}
    </React.Fragment>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  return {};
};

const mapDispatch = {};

const connector = connect(mapStateToProps, mapDispatch);

export default connector(FrequentlyAskQuestions);
