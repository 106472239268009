import ucmercedsealblue800D from "./Content/images/static/c6ac01eaea42251fe06898a5342151dd/ee604/uc-merced-seal-blue.png";
import ucmercedsealblue200 from "./Content/images/static/c6ac01eaea42251fe06898a5342151dd/69585/uc-merced-seal-blue.png";
import ucmercedsealblue400 from "./Content/images/static/c6ac01eaea42251fe06898a5342151dd/497c6/uc-merced-seal-blue.png";
import ucmercedsealblue1085 from "./Content/images/static/c6ac01eaea42251fe06898a5342151dd/4f0ce/uc-merced-seal-blue.png";

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider, connect } from "react-redux";
import ReduxToastr from "react-redux-toastr";

import "./App.scss";
import Header from "./header/Header";
import Dashboard from "./pages/dashboard/Dashboard";
import DepartmentPage from "./pages/departmentPage/DepartmentPage";
import DepartmentPreferences from "./pages/departmentPreferences/DepartmentPreferences";
import AdminPreferences from "./pages/adminPreferences/AdminPreferences";
import StudentPreferences from "./pages/studentPreferences/StudentPreferences";
import MyPreferences from "./pages/myPreferences/MyPreferences";
import Person from "./pages/person/Person";
import Footer from "./footer/Footer";
import store, { RootState } from "../store";
import { PrivateRoute } from "./protectedRoute/ProtectedRoute";
import FAQ from "./pages/faq/FAQ";
import NotFound from "./pages/notFound/NotFound";

const App = (props: Props) => {
  const { isSaving } = props;

  return (
    <React.Fragment>
      {isSaving && (
        <div className="SuccessState">Your changes have been saved!</div>
      )}
      <BrowserRouter>
        <div className="Layout">
          <div className="Layout-nonFooter">
            <div className="WatermarkSeal">
              <img
                src={ucmercedsealblue800D}
                srcSet={`${ucmercedsealblue200} 200w, ${ucmercedsealblue400} 400w, ${ucmercedsealblue800D} 800w, ${ucmercedsealblue1085} 1085w`}
                sizes="(max-width: 800px) 100vw, 800px"
                alt="UC Merced Seal"
              />
            </div>

            <Header />
            <Routes>
              <Route
                path="/"
                element={
                  <PrivateRoute
                    isPrivate={false}
                    title="Home"
                    component={Dashboard}
                  />
                }
              />
              <Route
                path="/faq"
                element={
                  <PrivateRoute
                    isPrivate={false}
                    title="Frequently Asked Questions"
                    component={FAQ}
                  />
                }
              />
              <Route
                path="/department/:deptCode"
                element={
                  <PrivateRoute
                    isPrivate={false}
                    title="Departments"
                    component={DepartmentPage}
                  />
                }
              />
              <Route
                path="/person/:ucmnetid"
                element={
                  <PrivateRoute
                    isPrivate={false}
                    title="Person - "
                    component={Person}
                  />
                }
              />
              <Route
                path="/departmentPreferences/:deptCode"
                element={
                  <PrivateRoute
                    isPrivate={true}
                    title="Departemnt Preferences"
                    component={DepartmentPreferences}
                  />
                }
              />
              <Route
                path="/adminPreferences/:ucmNetid"
                element={
                  <PrivateRoute
                    isPrivate={true}
                    title="Admin Preferences"
                    component={AdminPreferences}
                  />
                }
              />
              <Route
                path="/myPreferences"
                element={
                  <PrivateRoute
                    isPrivate={true}
                    title="My Preferences"
                    component={MyPreferences}
                  />
                }
              />
              <Route
                path="/studentPreferences/:ucmnetid"
                element={
                  <PrivateRoute
                    isPrivate={true}
                    title="Student Preferences"
                    component={StudentPreferences}
                  />
                }
              />
              <Route
                path="*"
                element={
                  <PrivateRoute
                    isPrivate={false}
                    title="Page Not Found"
                    component={NotFound}
                  />
                }
              />
            </Routes>
          </div>

          <Footer />
          <Provider store={store}>
            <div>
              <ReduxToastr
                timeOut={4000}
                newestOnTop={false}
                preventDuplicates
                position="bottom-right"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar
                closeOnToastrClick
              />
            </div>
          </Provider>
        </div>
      </BrowserRouter>
    </React.Fragment>
  );
};

type Props = { isSaving: boolean };

const mapStateToProps = (state: RootState) => {
  return { isSaving: state.globalState.isSaving };
};

export default connect(mapStateToProps)(App);
