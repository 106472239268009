import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../store";
import StudentInfo from "../../studentInfo/StudentInfo";

type Props = PropsFromRedux & {};

const StudentPreferences = (props: Props) => {
  return (
    <div className="PageWrapper">
      <div className="AdminPreferencesLayout">
        <StudentInfo />
      </div>
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  return {};
};

const mapDispatch = {};

const connector = connect(mapStateToProps, mapDispatch);

export default connector(StudentPreferences);
